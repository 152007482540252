import {useContext, useEffect, useRef, useState} from "react";
import UserContext from "../../context/user-context";
import axios from "axios";
import {Link} from "react-router-dom";
import AccessDenied from "../../layout/AccessDenied";

function VersionsPage(props){
    const userContext =  useContext(UserContext);
    const [getTotalPages,setTotalPages] = useState(0);
    const [versions, setVersions] = useState([]);
    const [totalVersions, setTotalVersions] = useState(0);
    const [pagesLinks,setPagesLinks] = useState([]);

    const queryRef = useRef();

    let currentPage = 1;

    function updateCurrentPage(p){
        currentPage = parseInt(p);
    }

    function getCurrentPage(){
        return parseInt(currentPage);
    }

    function reloadVersions(){

        var params = {
            q: queryRef.current.value,
            page: getCurrentPage(),
        };


        axios.get(userContext.getBackendUrl()+'/versions',{params: params})
            .then((response)=>{
                setVersions(response.data.data);
                setTotalVersions(response.data.total);
                setTotalPages(parseInt(response.data.last_page));

                let pagesLinksArr = [];
                for(let i=1;i<=parseInt(response.data.last_page); i++){

                    if(i===currentPage){
                        pagesLinksArr.push(<span className='btn btn-primary btn-sm m-1'  key={i}>{i}</span>)
                    }else{
                        pagesLinksArr.push(<span className='btn btn-light btn-sm m-1' onClick={()=> {  updateCurrentPage(i);reloadVersions(); }} key={i}>{i}</span>)
                    }

                }
                setPagesLinks(pagesLinksArr);
            }).catch((error)=>{
            alert('ERROR WHILE GETTING Versions!');
        });

    }


    useEffect(()=>{

        if(userContext.permissions.versions_view){
            reloadVersions();

        }
    },[]);

    return (
        <div className={'card'}>
            <div className={'card-header'}>
                VERSION CONTROL
                {userContext.permissions.versions_create ?
                    <Link to={'/versions/create'} className={'btn btn-sm btn-success float-end'}><i className={'fa fa-plus'} /> Добави нова версия</Link>
                    :
                    null}
            </div>

            {userContext.permissions.versions_view ?

                <div className={'card-body'}>
                    <div className={'row'}>
                        <div className={'col-lg-3'}>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><i className={'fa fa-search'} /> </span>
                                <input type={'text'} className={'form-control'} ref={queryRef} placeholder={'Търси в списък с версии...'}  onChange={()=>{ updateCurrentPage(1); reloadVersions();}} />
                            </div>
                        </div>
                        <div className={'col-lg-2 mb-2 ps-3 pt-2'}>
                            <span className={'badge bg-light text-dark'} style={{fontSize:'14px'}}>{totalVersions} резултата</span>
                        </div>
                    </div>
                    <div className={'row'}>
                        {versions.map((version)=>{
                            return ( <div key={version.id} className={'col-lg-12 col-md-12'}>
                                <h4 style={{marginBottom:'0px',color: '#ffffff',background:'linear-gradient(90deg, rgba(20,101,119,0.9864146342130602) 39%, rgba(255,255,255,0.2805322812718838) 100%)',paddingBottom:'5px',paddingLeft:'5px',fontWeight:'normal',paddingTop:'5px',borderTopLeftRadius:'5px'}}>{version.title}</h4>
                                <div style={{borderLeft:'3px solid rgba(20,101,119,0.9864146342130602)',paddingLeft:'10px',paddingTop:'10px'}}>
                                    <h6><i className={'fa fa-calendar-day'} /> {version.date} <span style={{opacity:0.5,fontWeight:'normal',fontSize:'12px'}}>| Added at {new Date(version.created_at).toLocaleDateString('bg-BG')} {new Date(version.created_at).toLocaleTimeString()} | Updated by {version.user.name} at {new Date(version.updated_at).toLocaleDateString('bg-BG')} {new Date(version.updated_at).toLocaleTimeString()}</span>

                                        {userContext.permissions.versions_edit ?
                                            <Link to={'/versions/'+version.id+'/edit'} className={'btn btn-light btn-sm float-end'}><i className={'fa fa-pencil'} /> Редактирай</Link>
                                            :null}
                                    </h6>
                                    <div className={'w-100'} dangerouslySetInnerHTML={{__html:version.description}}/>
                                </div>
                                <hr/>
                            </div>);
                        })}
                    </div>
                    <div className={'w-100'}>
                        {pagesLinks.map((pageLink)=>pageLink)}
                    </div>
                </div>
                :

                <AccessDenied />
            }

        </div>
    );
}

export default VersionsPage;
