import {Link} from "react-router-dom";
import {useContext} from 'react';
import UserContext from "../context/user-context";



function UserNavigation(){

    const userContext =  useContext(UserContext);

    function burgerMenuButtonClick(){
        var x = document.getElementById("sideBarContainer");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }

    }

    return (
        <div className='w-100'>
            <div className="dropdown pb-4">
                <a href="#"
                   className="d-flex align-items-center text-light text-decoration-none dropdown-toggle"
                   id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className='fa fa-user-circle' style={{fontSize: "20px"}} />
                    <span className="text-light mx-1 text-big" style={{fontSize: '16px'}}>{userContext.name === null ? (<span>Профил</span>) : (<span>{userContext.user.name} <span style={{opacity:0.4}}>{userContext.user.email}</span></span>) }</span>
                </a>

                {userContext.loggedIn===false ?
                    (<ul className="dropdown-menu dropdown-menu-dark text-small shadow"
                         aria-labelledby="dropdownUser1">
                        <li><Link className="dropdown-item" to="/login">Вписване</Link></li>
                    </ul>) :
                    (<ul className="dropdown-menu dropdown-menu-dark text-small shadow"
                         aria-labelledby="dropdownUser1">
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li><button className="dropdown-item" onClick={userContext.logout}>Отписване</button></li>
                    </ul>)
                }



            </div>
            <button type={'button'} onClick={burgerMenuButtonClick} style={{position:'fixed',top:'0px',right: '0px',height:'50px',width:'80px'}} className={'btn btn-dark border-sharp btn-lg hidden-desktop'}><i className={'fa fa-bars'} /></button>
        </div>
    );
}

export default UserNavigation;
