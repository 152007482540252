import axios from 'axios';

import {createContext, useState, useEffect} from 'react';
import { useCookies } from 'react-cookie'

const UserContext = createContext({
    loggedIn: false,
    systems: [],
    permissions: {},
    user: {},
    login: (email,password) => {},
    logout: () => {},
    reloadSystems: () => {},
});


export function UserContextProvider(props){

    const [profile, setProfile] = useState(
        {
                    loggedIn: false,
                    systems: [],
                    user: {},
                    permissions: {},
                    login: (email,password) => {},
                    logout: () => {},
                    getBackendUrl: () => {},
                    reloadSystems: () => {},
                }
    );
    const [accessToken, setAccessToken] = useCookies(['access_token_wtbills'])

    var date = new Date();
    var token = btoa(date.toLocaleString('de-DE'));

    axios.defaults.headers.common['Authorization'] = 'Bearer '+accessToken.access_token_wtbills;
    axios.defaults.headers.common['x-wtbills-api-key'] = token;



    function logout(){
        let expires = new Date()
        setAccessToken('access_token_wtbills', null, { path: '/',  expires});
        reloadUser();
    }


    function getBackendUrl(){
        var backendUrl = "";

        // return "https://react-backend.webtrade.bg/api";
        if(window.location.hostname === 'localhost'){
            backendUrl = "http://localhost:8000/api";
        }else backendUrl = "https://react-backend.webtrade.bg/api";

        return backendUrl;
    }


    function reloadSystems(){
        axios.get(getBackendUrl()+'/systems')
        .then((response)=>{

            setProfile((prevState) => ({
                ...prevState,
                systems: response.data,
            }));

        })
        .catch((error)=>{
            alert('Error while getting systems');
        });
    }

    function reloadUser(){
       axios.get(getBackendUrl()+"/profile")
       .then((response)=>{





           if(response.data.status === 'success'){
               var permissions = {};

               response.data.user.permissions.map((perm)=>{
                  permissions[perm.permission] = (parseInt(perm.access) > 0) ? true : false;
               });
               setProfile((prevState) => ({
                   loggedIn: true,
                   user: response.data.user,
                   systems: prevState.systems,
                   permissions: permissions,
                   login: login,
                   logout: logout,
                   getBackendUrl: getBackendUrl,
                   reloadSystems: reloadSystems,
               }));
           }else{
               setProfile({
                   loggedIn: false,
                   user: {},
                   systems: [],
                   permissions: {},
                   login: login,
                   logout: logout,
                   getBackendUrl: getBackendUrl,
                   reloadSystems: reloadSystems,
               });
           }
       }).catch((error)=>{
           setProfile({
               loggedIn: false,
               user: {},
               systems: [],
               permissions: {},
               login: login,
               logout: logout,
               getBackendUrl: getBackendUrl,
               reloadSystems: reloadSystems,
           });
       });
    }

    function login(email,password){
        axios.post(getBackendUrl()+'/login',{email,password})
        .then((response)=>{
            if(response.data.status === 'success'){
                setToken(response.data.token);
                reloadUser();

                return true;
            }else{
                return false;
            }
        }).catch((error)=>{
            return false;
        });

    }


    function setToken(token){
        let expires = new Date();
        expires.setTime(expires.getTime() +  (60*60*24*1000));
        setAccessToken('access_token_wtbills', token, { path: '/',  expires});
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token;

    }


    useEffect(()=>{


        reloadSystems();

        reloadUser();





    },[accessToken,setAccessToken]);


    return <UserContext.Provider value={profile}>
        {props.children}
    </UserContext.Provider>
}

export default UserContext;
