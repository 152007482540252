import logo from '../../images/wtbills-logo.png'
import {useContext, useRef, useState} from "react";
import UserContext from "../../context/user-context";



function LoginPage(){

    const userContext =  useContext(UserContext);

    const [currentErrors, setCurrentErrors] = useState([]);
    const emailRef = useRef();
    const passwordRef = useRef();

    function loginHandler(event){
        event.preventDefault();

        let email = emailRef.current.value;
        let password = passwordRef.current.value;

        var errors = [];

        if(userContext.login(email, password)){
            console.log("LOGGED SUCCESSFULLY");
        }else{
            errors.push("Wrong username or password");
            setCurrentErrors(errors);
        }



    }
    return (
        <div className='container-sm mt-5'>
            <div className='row'>
                <div className='col-lg-4 col-sm-12' />
                <div className='col-lg-4 col-sm-12'>
                    <div className='card shadow'>
                        <div className='card-body'>
                            <form onSubmit={loginHandler}>
                                <div className="input-group mb-3 mt-3 text-center">
                                    <img src={logo} alt='Logo' className='img-fluid' />
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{width:'120px'}}><i className='fa fa-envelope me-2' /> Email</span>
                                    <input type="email" className="form-control" id='username' placeholder='example@webtrade.bg' ref={emailRef}  required/>
                                </div>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{width:'120px'}}><i className='fa fa-lock me-2' /> Password</span>
                                    <input type="password" className="form-control" id='password' placeholder='**********' ref={passwordRef}  required/>
                                </div>
                                <div className='w-100 mb-3 text-end'>
                                    <button type="submit" className="btn btn-primary btn-block mb-4"><i className='fa fa-sign-in' /> Sign in</button>
                                </div>

                                {currentErrors.length > 0 ?
                                    <div className="w-100 mt-3">
                                        <div className='alert alert-warning p-2'>
                                            {currentErrors.map((error)=><p key={error} className='p-0 m-0'><i className='fa fa-ban' /> {error}</p>)}
                                        </div>
                                    </div> : null }

                            </form>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-12' />
            </div>


        </div>
    )

}

export default LoginPage;
