function AccessDenied(props){

    return (
        <div className={'alert alert-warning text-dark opacity-75 m-3'}>
            { (props.children) ?
                props.children:
                <p className={'mb-0'}>
                    <i className={'fa fa-exclamation-triangle'} /> Нямате достъп до тази функционалност. Моля, свържете се с администратор.
                </p>
            }
        </div>
    )
}

export default AccessDenied;
