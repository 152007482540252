import Modal from "react-modal";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Loading from "../../layout/Loading";


function ClientsList(props){
    const [clients,setClients] = useState([]);
    const [totalClients,setTotalClients] = useState(0);
    const [pagesLinks,setPagesLinks] = useState([]);
    const [getTotalPages,setTotalPages] = useState(0);
    const [isLoading,setIsLoading] = useState(false);

    const [clientId,setClientId] = useState(0);

    const [isModalOpen,setIsModalOpen] = useState(false);


    const searchRef = useRef();

    let currentPage = 1;
    function updateCurrentPage(p){
        currentPage = parseInt(p);
    }

    function getCurrentPage(){
        return parseInt(currentPage);
    }

    function reloadClients(){

        var params = {
            q: searchRef.current.value,
            page: getCurrentPage(),
        };

        axios.get(props.system.url+'/api/clients',{params: params})
            .then((response)=>{
                setClients(response.data.data);
                setTotalClients(response.data.total);
                setTotalPages(parseInt(response.data.last_page));

                let pagesLinksArr = [];
                for(let i=1;i<=parseInt(response.data.last_page); i++){

                    if(i===currentPage){
                        pagesLinksArr.push(<span className='btn btn-primary btn-sm m-1'  key={i}>{i}</span>)
                    }else{
                        pagesLinksArr.push(<span className='btn btn-light btn-sm m-1' onClick={()=> {  updateCurrentPage(i);reloadClients(); }} key={i}>{i}</span>)
                    }
                }
                setPagesLinks(pagesLinksArr);
            }).catch((error)=>{
            alert('ERROR WHILE GETTING Clients!');
        });
    }


    useEffect(()=>{

        reloadClients();
        setIsLoading( prevState => true);
        setTimeout( () => {
            setIsLoading( prevState => false);
        }, 1000);
    },[props.system]);


    if(isLoading){
        return <Loading height='200px' size='25px' />
    }

    return (
        <div className={'w-100'}>
            <div className={'row mb-2'}>
                <div className={'col-lg-12 col-sm-12'}>
                    <h5><i className={'fa fa-address-card'} /> Клиенти</h5>
                </div>
                <div className={'col-lg-4'}>
                    <input ref={searchRef} type='text' placeholder={'Търсене по Име, адрес, булстат.....'} onChange={(e)=>{ updateCurrentPage(1);     reloadClients(); }} className={'form-control'}   />
                </div>

            </div>
            <p>Резултати <span className={'badge bg-dark text-light'}>{totalClients}</span></p>

            <table className='table table-sm table-hover'>
                <thead>
                <tr>
                    <th></th>
                    <th>#</th>
                    <th>Клиент</th>
                    <th>Адрес</th>
                    <th>БУЛСТАТ</th>
                    <th>Тел. номер</th>
                    <th>Email</th>
                    <th>Статус</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {clients.map((client) =>
                    <tr key={client.id} style={{cursor:'pointer'}} >
                        <td onClick={()=>{ setClientId(client.id); setIsModalOpen(true); }}>
                            <img src={(client.filename !== null ? props.system.url+'/upload/clientPhotos/'+client.filename : props.system.url+'/img/default-client-logo.jpg')  } className={'img-fluid img-thumbnail'} style={{maxWidth:'120px'}} />
                        </td>
                        <td className={'opacity-50'} onClick={()=>{ setClientId(client.id); setIsModalOpen(true); }}><b>{client.number}</b></td>
                        <td onClick={()=>{ setClientId(client.id); setIsModalOpen(true); }}><b>{client.name}</b></td>
                        <td onClick={()=>{ setClientId(client.id); setIsModalOpen(true); }}>{client.address}</td>
                        <td onClick={()=>{ setClientId(client.id); setIsModalOpen(true); }}>{client.bulstat}</td>
                        <td onClick={()=>{ setClientId(client.id); setIsModalOpen(true); }}>{client.phone}</td>
                        <td onClick={()=>{ setClientId(client.id); setIsModalOpen(true); }}>{client.email}</td>
                        <td onClick={()=>{ setClientId(client.id); setIsModalOpen(true); }}>{client.deactivated ? <span className={'badge bg-light text-danger'}>деактивиран</span>:<span className={'badge bg-light text-success'}>активен</span>}</td>
                        <td>
                            {clientId > 0 && clientId === client.id ?
                                <div>
                                    <Modal isOpen={isModalOpen}
                                           animationDuration={10000}
                                           ariaHideApp={false}
                                           style={{
                                               overlay:
                                                   {
                                                       backgroundColor: 'rgb(0,0,0,0.7)',
                                                   },
                                               content: {
                                                   minWidth:'60vw',
                                                   top: '40%',
                                                   boxShadow: '1px 1px 15px black',
                                                   borderRadius:'10px',
                                                   backgroundColor: 'white',
                                                   left: '50%',
                                                   right: 'auto',
                                                   bottom: 'auto',
                                                   marginRight: '-50%',
                                                   transform: 'translate(-50%, -50%)',
                                               }

                                           }}
                                    >
                                        <h5>{client.name}</h5>
                                        <h6 className={'opacity-50'}>{client.name_en}</h6>
                                        <table className={'table table-bordered table-striped'}>
                                            <tr>

                                                <td style={{verticalAlign:'top'}}>
                                                    <span style={{opacity:0.5}}>Номер</span> <br/>
                                                    <b className={'text-primary'}>{client.number}</b>
                                                </td>
                                                <td style={{verticalAlign:'top'}}>
                                                    <span style={{opacity:0.5}}>Тел. номер</span> <br/>
                                                    <b className={'text-primary'}>{client.phone}</b>
                                                </td>
                                                <td style={{verticalAlign:'top'}}>
                                                    <span style={{opacity:0.5}}>E-Mail</span> <br/>
                                                    <b className={'text-primary'}>{client.email}</b>
                                                </td>
                                                <td style={{verticalAlign:'top'}}>
                                                    <span style={{opacity:0.5}}>Добавен на</span> <br/>
                                                    <b className={'text-primary'}>{new Date(client.created_at).toDateString()} {new Date(client.created_at).toLocaleTimeString()}</b>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{verticalAlign:'top'}}>
                                                    <span style={{opacity:0.5}}>МОЛ</span> <br/>
                                                    <b className={'text-primary'}>{client.mol}</b> <br/>
                                                    <i>{client.mol_en}</i>
                                                </td>
                                                <td style={{verticalAlign:'top'}}>
                                                    <span style={{opacity:0.5}}>Адрес</span> <br/>
                                                    <b className={'text-primary'}>{client.address}</b> <br/>
                                                    <i>{client.address_en}</i>
                                                </td>
                                                <td style={{verticalAlign:'top'}}>
                                                    <span style={{opacity:0.5}}>БУЛСТАТ</span> <br/>
                                                    <b className={'text-primary'}>{client.bulstat}</b>
                                                </td>
                                                <td style={{verticalAlign:'top'}}>
                                                    <span style={{opacity:0.5}}>ДДС</span> <br/>
                                                    <b className={'text-primary'}>{client.dds}</b>
                                                </td>
                                            </tr>
                                        </table>


                                        <div className={'row'}>
                                            <div className={'col-lg-6'}>
                                                <i className={'fa fa-users'}/> Потребители към клиент
                                                <hr/>

                                                {client.users.length ?

                                                    <table className={'table table-sm'}>
                                                        <thead>
                                                        <tr>
                                                            <th>Имена</th>
                                                            <th>Email</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {client.users.map((user)=>{
                                                            return (
                                                                <tr key={user.id}>
                                                                    <td>
                                                                        {user.name}
                                                                    </td>
                                                                    <td>{user.email}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                    : <div className={'alert alert-info'}><i className={'fa fa-times'} /> Няма намерена информация</div>}

                                            </div>
                                            <div className={'col-lg-6'}>
                                                <i className={'fa fa-file-signature'}/> Договори към клиент
                                                <hr/>

                                                {client.contracts.length ?

                                                    <table className={'table table-sm'}>
                                                        <thead>
                                                        <tr>
                                                            <th>Номер</th>
                                                            <th>Дата на подписване</th>
                                                            <th>От дата</th>
                                                            <th>До дата</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {client.contracts.map((contract)=>{
                                                            return (
                                                                <tr key={contract.id}>
                                                                    <td>
                                                                        {contract.number}
                                                                    </td>
                                                                    <td>{contract.dateSign}</td>
                                                                    <td>{contract.dateFrom}</td>
                                                                    <td>{contract.dateTo}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                    : <div className={'alert alert-info'}><i className={'fa fa-times'} /> Няма намерена информация</div>}

                                            </div>
                                            <div className={'col-lg-6'}>
                                                <i className={'fa fa-envelope'}/> Лица за получаване на фактури
                                                <hr/>

                                                {client.contacts.length ?

                                                    <table className={'table table-sm'}>
                                                        <thead>
                                                        <tr>
                                                            <th>Имена</th>
                                                            <th>Тел. номер</th>
                                                            <th>Email</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {client.contacts.map((contact)=>{
                                                            return (
                                                                <tr key={contact.id}>
                                                                    <td>{contact.name}</td>
                                                                    <td>{contact.phone}</td>
                                                                    <td>{contact.email}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                    : <div className={'alert alert-info'}><i className={'fa fa-times'} /> Няма намерена информация</div>}

                                            </div>
                                        </div>




                                        <button className={'btn btn-outline-dark float-end'} type='button' onClick={()=>{ setIsModalOpen(false); setClientId(0); }}><i className={'fa fa-times'} /> Затвори</button>
                                    </Modal>
                                </div>

                                : null}
                        </td>
                    </tr>
                )}
                </tbody>
            </table>



            <div className={'w-100'}>
                {pagesLinks.map((pageLink)=>pageLink)}
            </div>


        </div>
    );

}


export default ClientsList;
