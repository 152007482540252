
import Modal from "react-modal";
import {useContext, useState} from "react";
import { useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
import UserContext from "../../context/user-context";
import axios from "axios";

function VersionDeleteModal(props){
    const [isModalOpen, setIsModalOpen] = useState(false);
    let navigate = useNavigate();
    const userContext =  useContext(UserContext);


    function deleteVersion(){
        axios.delete(userContext.getBackendUrl()+'/versions/'+props.version.id+'/delete')
            .then((response)=>{
                if(response.data.status === 'success'){
                    setIsModalOpen(false);
                    toast.success('Версията беше премахната', { position: toast.POSITION.BOTTOM_RIGHT  });
                    setTimeout(()=>{
                        navigate(-1);
                    },1000)
                }else{
                    toast.success('Грешка при премахване', { position: toast.POSITION.BOTTOM_RIGHT  });

                }

            }).catch((error)=>{
            toast.success('Грешка при премахване', { position: toast.POSITION.BOTTOM_RIGHT  });

        });

    }


    return (
        <div>
            <button onClick={()=>setIsModalOpen(true)} className={'btn btn-danger btn-sm'}><i className={'fa fa-trash-alt'} /> Премахване</button>
            <Modal isOpen={isModalOpen}
                   animationDuration={10000}
                   ariaHideApp={false}
                   style={{
                       overlay:
                           {
                               backgroundColor: 'rgb(0,0,0,0.7)',
                           },
                       content: {
                           minWidth:'60vw',
                           top: '40%',
                           boxShadow: '1px 1px 15px black',
                           borderRadius:'10px',
                           backgroundColor: 'white',
                           left: '50%',
                           right: 'auto',
                           bottom: 'auto',
                           marginRight: '-50%',
                           transform: 'translate(-50%, -50%)',
                       }

                   }}
            >

                <div className={'alert alert-warning'}>
                    Потвърдете премахването на тази версия: <b>{props.version.title}</b> <br/>

                    <div style={{maxWidth:'400px'}} dangerouslySetInnerHTML={{__html: props.version.description}} />

                    <div className={'w-100 mt-2 text-end'}>
                        <button className={'btn btn-danger'} onClick={deleteVersion}><i className={'fa fa-trash'} /> ПРЕМАХВАНЕ</button>
                    </div>
                </div>
                <button className={'btn btn-outline-dark float-start'} type='button' onClick={()=>{ setIsModalOpen(false); }}><i className={'fa fa-times'} /> Затвори</button>

            </Modal>
        </div>

    );


}



export default VersionDeleteModal;
