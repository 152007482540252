import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import UserContext from "../../context/user-context";
import {Link, useParams} from "react-router-dom";
import SystemCard from "./SystemCard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from "react-quill";
import Loading from "../../layout/Loading";
import SystemDeleteModal from "./SystemDeleteModal";
import AccessDenied from "../../layout/AccessDenied";

function SystemEdit(){
    const userContext =  useContext(UserContext);
    const searchRef = useRef();
    const [system,setSystem] = useState({});
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [description, setDescription] = useState('');






    function updateSystem(){


        var payload = {
            name: system.name,
            url: system.url,
            description: description,
        }

        axios.post(userContext.getBackendUrl()+'/systems/'+params.systemId+'/update',payload)
            .then((response)=>{

                if(response.data.status === 'success'){
                    toast.success('Информацията беше обновена', { position: toast.POSITION.BOTTOM_RIGHT  });

                    userContext.reloadSystems();
                }else{
                    toast.error('Грешка при обновяване', { position: toast.POSITION.BOTTOM_RIGHT  });
                }
            }).catch((err)=>{
              toast.error('Грешка при обновяване', { position: toast.POSITION.BOTTOM_RIGHT  });

             });


    }

    function reloadSystem(){

        setIsLoading(true);
        axios.get(userContext.getBackendUrl()+'/systems/'+params.systemId+'/show')
            .then((response)=>{
                setSystem(response.data);
                setDescription(response.data.description);
                setIsLoading(false);
            })
            .catch((error)=>{
                alert('Error while getting systems');
            });
    }

    useEffect(()=>{

        if(userContext.permissions.systems_edit){
            reloadSystem();
        }
    },[])


    if(userContext.permissions.systems_edit === false){
        return (
            <div className={'card'}>
                <div className={'card-body'}>
                    <AccessDenied />
                </div>
            </div>
        )
    }

    if(isLoading){
        return (
            <div className={'card'}>
                <div className={'card-body'}>
                    <Loading />
                </div>
            </div>
        )
    }
    return (
        <div className={'card'}>
            <div className={'card-header'}>

                <div className={'row'}>
                    <div className={'col-lg-6 col-sm-12'}>
                        {system.name}
                    </div>
                    <div className={'col-lg-6 col-sm-12 text-end'}>
                        {userContext.permissions.systems_delete ?
                            <SystemDeleteModal system={system} />
                        :null}
                    </div>
                </div>
            </div>
            <div className={'card-body'}>

                <input type={'text'} className={'form-control'} value={system.name} onChange={(e)=> {setSystem(p =>{ return { ...p,name: e.target.value} })}} />
                <input type={'url'} className={'form-control mt-2'} value={system.url} onChange={(e)=> {setSystem(p =>{ return { ...p,url: e.target.value} })}} />
                <ReactQuill style={{marginTop:'10px'}} theme="snow" value={description} onChange={setDescription} />

                <br/>
                <button type={'button'} className={'btn btn-success float-end mt-2'} onClick={()=>{ updateSystem() }}><i className={'fa fa-save'} /> Обнови</button>

                <ToastContainer />
            </div>
        </div>
    );
}


export default SystemEdit;
