import {useEffect, useRef, useState} from "react";
import axios from "axios";

import Modal from 'react-modal';
import Loading from "../../layout/Loading";



function InvoicesList(props){

    const [invoices,setInvoices] = useState([]);
    const [totalInvoices,setTotalInvoices] = useState(0);
    const [getTotalPages,setTotalPages] = useState(0);
    const [clients,setClients] = useState([]);
    const [pagesLinks,setPagesLinks] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const [invoiceId,setInvoiceId] = useState(0);

    const [isModalOpen,setIsModalOpen] = useState(false);


    const typeRef = useRef();
    const clientIdRef = useRef();
    const numberRef = useRef();

    let currentPage = 1;


    useEffect(()=>{
        axios.get(props.system.url+'/api/clients',{params: {list: '1'}})
            .then((response)=>{
                setClients(response.data);
            }).catch((error)=>{
            alert('ERROR WHILE GETTING Clients!');
        });

        setIsLoading( prevState => true);
        setTimeout( () => {
            setIsLoading( prevState => false);
        }, 1000);

    },[]);


    function updateCurrentPage(p){
        currentPage = parseInt(p);
    }

    function getCurrentPage(){
        return parseInt(currentPage);
    }

    function reloadInvoices(){

        var params = {
            client_id: clientIdRef.current.value,
            type: typeRef.current.value,
            number: numberRef.current.value,
            page: getCurrentPage(),
        };


        axios.get(props.system.url+'/api/invoices',{params: params})
            .then((response)=>{
                setInvoices(response.data.data);
                setTotalInvoices(response.data.total);
                setTotalPages(parseInt(response.data.last_page));

                let pagesLinksArr = [];
                for(let i=1;i<=parseInt(response.data.last_page); i++){

                    if(i===currentPage){
                        pagesLinksArr.push(<span className='btn btn-primary btn-sm m-1'  key={i}>{i}</span>)
                    }else{
                        pagesLinksArr.push(<span className='btn btn-light btn-sm m-1' onClick={()=> {  updateCurrentPage(i);         reloadInvoices(); }} key={i}>{i}</span>)
                    }

                }
                setPagesLinks(pagesLinksArr);
            }).catch((error)=>{
            alert('ERROR WHILE GETTING Invoices!');
        });
    }

    function typeToName(id){
        switch(parseInt(id)){
            case 1: return "Фактура";
            case 2: return "Дебитно известие";
            case 3: return "Кредитно известие";
            case 4: return "Проформа фактура";
        }
    }

    useEffect(()=>{

        reloadInvoices();

    },[props.system]);




    if(isLoading){
        return <Loading height='200px' size='25px' />
    }

    return (
        <div className={'w-100'}>
            <div className={'row mb-2'}>
                <div className={'col-lg-12 col-sm-12'}>
                    <h5><i className={'fa fa-file-invoice'} /> Финансови документи</h5>
                </div>
                <div className={'col-lg-4'}>
                    <input type='text' placeholder={'Търсене по №'} onChange={(e)=>{ updateCurrentPage(1);     reloadInvoices(); }} className={'form-control'} ref={numberRef} />
                </div>
                <div className={'col-lg-4'}>
                    <select ref={typeRef}  className={'form-control'} onChange={(e)=>{ updateCurrentPage(1);     reloadInvoices(); }}>
                        <option value='0'>Всички видове</option>
                        <option value='1'>Фактури</option>
                        <option value='2'>Дебитни известия</option>
                        <option value='3'>Кредитни известия</option>
                        <option value='4'>Проформа фактури</option>
                    </select>
                </div>
                <div className={'col-lg-4'}>
                    <select  ref={clientIdRef} className={'form-control'} onChange={(e)=>{   updateCurrentPage(1);  reloadInvoices(); }}>
                        <option value='0'>Всички клиенти</option>
                        {clients.map((client) => {
                           return  (<option key={client.id} value={client.id}>{client.name}</option>);
                        })}
                    </select>
                </div>
            </div>
            <p>Резултати <span className={'badge bg-dark text-light'}>{totalInvoices}</span></p>

            <table className='table table-sm table-hover'>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Вид</th>
                    <th>Дата</th>
                    <th>Клиент</th>
                    <th className={'text-end'}>Стойност <span className={'badge bg-dark text-light'}>BGN</span></th>

                </tr>
                </thead>
                <tbody>
                {invoices.map((invoice) =>
                    <tr key={invoice.id} style={{cursor:'pointer'}}>
                        <td onClick={()=>{ setInvoiceId(invoice.id); setIsModalOpen(true)} }>
                            <span className={'badge bg-light text-dark'}>{invoice.number}</span>
                            {invoice.canceled ? <span className={'badge bg-light text-danger'} style={{opacity:1}}><i className={'fa fa-ban'} /></span>:null}

                        </td>
                        <td onClick={()=>{ setInvoiceId(invoice.id); setIsModalOpen(true)} }>{typeToName(invoice.type)}</td>
                        <td onClick={()=>{ setInvoiceId(invoice.id); setIsModalOpen(true)} }>{invoice.date}</td>
                        <td onClick={()=>{ setInvoiceId(invoice.id); setIsModalOpen(true)} }>{invoice.client.name}</td>
                        <td className={'text-end'}><b>{parseFloat(invoice.price).toFixed(2)}</b>

                            {invoiceId > 0 && invoiceId === invoice.id ?
                                <div>
                                    <Modal isOpen={isModalOpen}
                                           animationDuration={10000}
                                           ariaHideApp={false}
                                           style={{
                                               overlay:
                                                   {
                                                     backgroundColor: 'rgb(0,0,0,0.7)',
                                                   },
                                               content: {
                                                   minWidth:'60vw',
                                                   top: '40%',
                                                   boxShadow: '1px 1px 15px black',
                                                   borderRadius:'10px',
                                                   backgroundColor: 'white',
                                                   left: '50%',
                                                   right: 'auto',
                                                   bottom: 'auto',
                                                   marginRight: '-50%',
                                                   transform: 'translate(-50%, -50%)',
                                               }

                                           }}
                                    >
                                        <p>{invoice.canceled ? <span className={'badge bg-light text-danger'} style={{opacity:1}}><i className={'fa fa-ban'} /> АНУЛИРАН</span>:null} Финансов документ № <b>{invoice.number}</b>
                                            <span className={'badge bg-primary text-white float-end'}>{typeToName(invoice.type)}</span>
                                        </p>


                                        <table className={'table table-bordered'}>
                                            <tr>
                                                <td><span className={'text-primary'}>Дата</span> <br/> {invoice.date}</td>
                                                <td><span className={'text-primary'}>Падеж</span> <br/> {invoice.payment_date}</td>
                                                <td><span className={'text-primary'}>Период (от)</span><br/> {invoice.dateFrom ?? <span className={'badge bg-light text-dark'}><i className={'fa fa-times'} /> няма избран</span>}</td>
                                                <td><span className={'text-primary'}>Период (до)</span><br/> {invoice.dateTo ?? <span className={'badge bg-light text-dark'}><i className={'fa fa-times'} /> няма избран</span>}</td>
                                            </tr>
                                            <tr>
                                                <td><span className={'text-primary'}>Клиент</span> <br/> {invoice.client.name}</td>
                                                <td><span className={'text-primary'}>Договор</span> <br/> {invoice.contract !== null ?  invoice.contract.number  :<span className={'badge bg-light text-dark'}>няма избран</span>}</td>
                                                <td><span className={'text-primary'}>Анекс / Приложение</span> <br/> {invoice.annex !== null ?  invoice.annex.number  :<span className={'badge bg-light text-dark'}><i className={'fa fa-times'} /> няма избран</span>}</td>
                                                <td><span className={'text-primary'}>Валути</span> <br/> {invoice.currencies}</td>
                                            </tr>
                                            <tr>
                                                <td><span className={'text-primary'}>Създаден от</span><br/> {invoice.user.name}<br/> {new Date(invoice.created_at).toLocaleDateString('bg-BG')} {new Date(invoice.created_at).toLocaleTimeString()}</td>
                                                <td><span className={'text-primary'}>Получен от</span> <br/> {invoice.received_by}</td>
                                                <td><span className={'text-primary'}>Прегледана</span> <br/> {invoice.viewed_at !== null ? <span><i className={'fa fa-check'} /> {invoice.viewed_at} </span>  :<span className={'badge bg-light text-dark'}><i className={'fa fa-times'} /> не е прегледан</span>}</td>
                                                <td><span className={'text-primary'}>Език: </span> <br/> {invoice.lang}</td>
                                            </tr>
                                        </table>


                                        <p>Елементи: <span className={'badge bg-dark text-light'}>{invoice.items.length}</span></p>
                                        <table className={'table table-sm'} style={{fontSize:'12px'}}>
                                            <thead>
                                            <tr>
                                                <th>Номенклатура</th>
                                                <th>КОД</th>
                                                <th>Мерна ед.</th>
                                                <th>К-во</th>
                                                <th>Ед. цена</th>
                                                <th>Общо</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {invoice.items.map((item)=>(
                                                <tr key={item.id}>
                                                    <td>{item.name} <br/> <span style={{fontStyle:'italic',fontSize:'80%'}}>{item.name_en}</span></td>
                                                    <td>{item.object}</td>
                                                    <td>{item.unit}</td>
                                                    <td>{item.qty}</td>
                                                    <td>{item.single_price}</td>
                                                    <td>{parseFloat(item.price).toFixed(2)}</td>
                                                </tr>
                                            ))}


                                            <tr>
                                                <td />
                                                <td colSpan={4}>Стойност на сделката</td>
                                                <td className={'text-end'}>
                                                    {invoice.items.reduce((accumulator, object) => { return parseFloat(accumulator) + parseFloat(object.price); }, 0).toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td />
                                                <td colSpan={4}>ДДС</td>
                                                <td className={'text-end'}>
                                                    {invoice.items.reduce((accumulator, object) => { return parseFloat(accumulator) + parseFloat(object.price) * (parseFloat(object.dds)/100); }, 0).toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr style={{fontSize:'120%'}}>
                                                <td/>
                                                <td colSpan={4} style={{backgroundColor: 'ghostwhite'}}>Сума за плащане</td>
                                                <td className={'text-end'} style={{backgroundColor: 'ghostwhite',fontWeight:'bold'}}>{parseFloat(invoice.price).toFixed(2)}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <button className={'btn btn-outline-dark float-end'} type='button' onClick={()=>{ setIsModalOpen(false); setInvoiceId(0); }}><i className={'fa fa-times'} /> Затвори</button>
                                    </Modal>
                                </div>


                                : null}
                        </td>

                    </tr>
                )}
                </tbody>
            </table>



            <div className={'w-100'}>
                {pagesLinks.map((pageLink)=>pageLink)}
            </div>


        </div>
        );
}

export default InvoicesList;
