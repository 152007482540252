import {Link} from "react-router-dom";
import {useContext} from "react";
import logo from '../images/wtbills-logo.png'
import classes from '../css/General.module.css'
import UserContext from "../context/user-context";


function SideBar(){
    const userContext =  useContext(UserContext);
    return (
        <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <img src={logo} className={'img-fluid me-5 mt-4 '} alt={'WTBills'} style={{filter: 'brightness(0) invert(1)'}} />
            <Link to="/" style={{backgroundColor:'#EA7428',display:'block',width:'100%',textDecoration:'none'}} className="px-0 text-light pt-2 pb-2 mt-2 rounded">
                <i className="ms-2 fa fa-desktop" />
                <span className="ms-1">Dashboard</span>
            </Link>
            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-sm-start"
                id="menu">
                {userContext.systems.map((system)=><li key={system.id}><Link to={'/system/'+system.id+'/preview'} className={"nav-link align-middle px-0 text-light "+classes.slideRightAnimated}><span className='ms-2 badge bg-light text-dark'><i className=' fa fa-terminal' /></span> <span style={{fontWeight: 'bold',color: '#EA7428'}}>{system.name}</span> <br/> <span className='ms-2' style={{opacity:0.6}}>{system.url}</span></Link></li>)}


                {userContext.permissions.systems_view ?
                <li>
                    <hr/><Link to={'/systems'} className={"nav-link align-middle px-0 text-light "+classes.slideRightAnimated}><span className='ms-2 badge bg-light text-dark'><i className='fas fa-th-large' /></span> <span style={{fontWeight: 'bold',color: '#5282f5',paddingTop:'10px'}}>SYSTEMS</span> <br/> <span className='ms-2' style={{opacity:0.6}}>Preview list with current systems</span></Link>
                </li>
                    :null}

                {userContext.permissions.versions_view ?
                    <li>
                        <Link to={'/versions'} className={"nav-link align-middle px-0 text-light "+classes.slideRightAnimated}><span className='ms-2 badge bg-light text-dark'><i className='fas fa-code-branch' /></span> <span style={{fontWeight: 'bold',color: '#5282f5',paddingTop:'10px'}}>VERSION CONTROL</span> <br/> <span className='ms-2' style={{opacity:0.6}}>Preview and update versions</span></Link>
                    </li>
                : null}

                {userContext.permissions.users_view ?
                    <li>
                        <Link to={'/users'} className={"nav-link align-middle px-0 text-light "+classes.slideRightAnimated}><span className='ms-2 badge bg-light text-dark'><i className='fas fa-users' /></span> <span style={{fontWeight: 'bold',color: '#5282f5',paddingTop:'10px'}}>USERS</span> <br/> <span className='ms-2' style={{opacity:0.6}}>Preview list of operators</span></Link>
                    </li>
                :  null}
            </ul>
        </div>
    );
}



export default SideBar;
