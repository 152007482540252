
function Loading(props){

    return (
        <div className='w-100 d-flex align-items-center text-center' style={{height: props.height}}>
            <div className='w-100'>
                <span style={{fontSize: props.size ? props.size:'100%',color: '#3198e8'}}><i className='fa-solid fa-spinner fa-spin' /> Loading...</span>
            </div>
        </div>
    );

}

export default Loading;
