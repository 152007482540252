import {Routes, Route} from 'react-router-dom';
import Layout from "./layout/Layout";
import UserContext from "./context/user-context";
import {useContext} from "react";
import LoginPage from "./pages/auth/LoginPage";
import Dashboard from "./pages/Dashboard";
import SystemPage from "./pages/system/SystemPage";
import VersionsPage from "./pages/versions/VersionsPage";
import VersionEdit from "./pages/versions/VersionEdit";
import VersionCreate from "./pages/versions/VersonCreate";
import SystemsPage from "./pages/system/SystemsPage";
import SystemEdit from "./pages/system/SystemEdit";
import SystemAdd from "./pages/system/SystemAdd";
import UsersPage from "./pages/auth/UsersPage";
import UserAdd from "./pages/auth/UserAdd";
import UserShow from "./pages/auth/UserShow";
import UserEdit from "./pages/auth/UserEdit";


function App() {
    const userContext =  useContext(UserContext);

    return (
        userContext.loggedIn ?
              <Layout>
                  <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/system/:systemId/preview" element={<SystemPage />} />
                      <Route path="/systems/:systemId/edit" element={<SystemEdit />} />
                      <Route path='/systems/create' element={<SystemAdd />} />

                      <Route path='/systems' element={<SystemsPage />} />

                      <Route path='/users' element={<UsersPage />} />
                      <Route path='/users/:userId/show' element={<UserShow />} />
                      <Route path='/users/:userId/edit' element={<UserEdit />} />
                      <Route path='/users/create' element={<UserAdd />} />

                      <Route path="/versions" element={<VersionsPage />} />
                      <Route path="/versions/create" element={<VersionCreate />} />
                      <Route path="/versions/:versionId/edit" element={<VersionEdit />} />
                  </Routes>
              </Layout>
            :
        <LoginPage />

  );
}

export default App;
