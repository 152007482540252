import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import Loading from "../../layout/Loading";
import UsersList from "../users/UsersList";
import InvoicesList from "../invoices/InvoicesList";
import ClientsList from "../clients/ClientsList";
import ItemsList from "../accounting/ItemsList";
import Limits from "../limits/Limits";
import SettingsList from "../SettingsList";
import FrontPage from "../front_page/FrontPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserContext from "../../context/user-context";
import SystemDeleteModal from "./SystemDeleteModal";
import AccessDenied from "../../layout/AccessDenied";
import SystemStop from "./SystemStop";


function SystemPage(props){
    const params = useParams();
    const userContext =  useContext(UserContext);

    const [systemInfo,setSystemInfo] = useState({});

    const [isLoading,setIsLoading] = useState(false);
    const [section,setSection] = useState('front_page');






    useEffect(()=>{
        if(userContext.permissions.systems_view){
            axios.get(userContext.getBackendUrl()+"/systems/"+params.systemId+"/show")
                .then((response)=>{

                    var system = response.data;

                    axios(system.url+'/api/info_simple').then((response)=>{
                        setSystemInfo({id: system.id, name: system.name,url: system.url,info: response.data,online:true});
                    }).catch((error)=>{
                        setSystemInfo({id: system.id, name: system.name,url: system.url,info: null,online:false});
                    });


                })
                .catch((error)=>{
                    var system = {};
                    setSystemInfo({id: 0,name: "",url: system.url,info: null,online:false});
                });
        }

    },[params]);


    if(userContext.permissions.systems_view === false){

        return <AccessDenied />
    }

    if(isLoading){
        return <div className={'card'}>
            <div className='card-header'>
                <b style={{color: 'whitesmoke',fontSize:'16px'}} className={'badge bg-dark'}><span className={'badge bg-light text-dark'}><i className=' fa fa-terminal' /></span> <span style={{paddingTop:'3px',paddingLeft:'5px',display:'block',float:'right'}}>Loading....</span></b>  <span className={'badge bg-light text-dark ms-3'}><i className={'fa fa-external-link'} /> <a href={systemInfo.url} style={{textDecoration:'none',color: '#EA7428'}} target='_blank'>{systemInfo.url}</a></span>
                {systemInfo.online ? <span className='badge bg-success float-end'>ONLINE</span> : <span className={'badge bg-danger float-end'}>OFFLINE</span>}
            </div>
            <div className={'card-body'}>
                <Loading height='500px' size='25px' />
            </div>
        </div>
    }

    return (

        (systemInfo.hasOwnProperty('name')) ?
        <div className='card'>
            <div className='card-header ps-1' style={{background:'linear-gradient(90deg, rgba(20,101,119,0.9864146342130602) 39%, rgba(255,255,255,0.2805322812718838) 100%)'}}>

                <b style={{color: 'whitesmoke',fontSize:'16px'}} className={'badge'}><span style={{paddingTop:'3px',paddingLeft:'2px',float:'right'}}>{systemInfo.name}</span></b>
                {systemInfo.online ? <span className='badge bg-success float-end'>ONLINE</span> : <span className={'badge bg-danger float-end'}>OFFLINE</span>}

             </div>
            <div className='card-body'>

                <div className='row'>
                    {systemInfo.hasOwnProperty('info') && systemInfo.info !== null && systemInfo.info.hasOwnProperty('logo') && systemInfo.info.logo !== null ?
                        <div className='col-12'> <img className={'float-end'} src={systemInfo.url+'/upload/settings/'+systemInfo.info.logo} style={{maxHeight:'30px',marginRight:'0px'}} />
                            <hr/>
                        </div> : null
                    }




                    <div className='col-lg-7 col-sm-12'>
                        <h5><i className={'fa-solid fa-square-poll-horizontal'} /> УПРАВЛЕНИЕ НА ОГРАНИЧЕНИЯ</h5>
                        <hr/>

                        {systemInfo.hasOwnProperty('info') && systemInfo.info !== null ?
                            <div>{userContext.permissions.systems_limits ? <Limits system={systemInfo}/> : <AccessDenied>{userContext.user.permissions.systems_limits}</AccessDenied> }</div>
                            : <div className='alert alert-warning'><i className={'fa fa-exclamation-triangle'} /> Няма намерена информация</div>
                        }

                        <h5><i className={'fa-solid fa-chart-bar'} /> ТЕКУЩ СТАТУС</h5>
                        <hr/>
                        {systemInfo.hasOwnProperty('info') && systemInfo.info !== null  ?
                            <div className='w-100 mt-1'>
                                <table className={'table table-sm table-striped'} >
                                    <tbody>
                                        <tr>
                                            <td>Брой потребители</td>
                                            <td><span className={'badge bg-light text-primary float-end'} style={{fontSize:'14px'}}>{systemInfo.info.users}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Брой оператори</td>
                                            <td><span className={'badge bg-light text-primary float-end'} style={{fontSize:'14px'}}>{systemInfo.info.operators}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Брой финансови документи</td>
                                            <td><span className={'badge bg-light text-primary float-end'} style={{fontSize:'14px'}}>{systemInfo.info.invoices}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Брой клиенти</td>
                                            <td><span className={'badge bg-light text-primary float-end'} style={{fontSize:'14px'}}>{systemInfo.info.clients}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Брой договори</td>
                                            <td><span className={'badge bg-light text-primary float-end'} style={{fontSize:'14px'}}>{systemInfo.info.contracts}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Брой анекси</td>
                                            <td><span className={'badge bg-light text-primary float-end'} style={{fontSize:'14px'}}>{systemInfo.info.annexes}</span></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>:
                            <div className='alert alert-warning'><i className={'fa fa-exclamation-triangle'} /> Няма намерена информация</div>
                        }

                    </div>
                    <div className='col-lg-5 col-sm-12'>

                        <h5><i className={'fa fa-ban'} /> СПИРАНЕ НА СИСТЕМАТА</h5>
                        <hr/>


                        {systemInfo.hasOwnProperty('info') && systemInfo.info !== null ?

                            <div>


                                {userContext.permissions.systems_stop ?

                                    <SystemStop  system={systemInfo}  />

                                :<AccessDenied />
                                }


                            </div>
                        :

                            <div className={'alert alert-warning'}><i className={'fa fa-exclamation-triangle'} /> Няма връзка с адрес: <b>{systemInfo.url}</b></div>}


                        <h5 className={'mt-3'}><i className={'fa fa-pencil'} /> Редакция и изтриване</h5>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col-lg-6 col-sm-12'} />
                            <div className={'col-lg-3 col-sm-12'} >
                                {userContext.permissions.systems_edit ?
                                    <Link to={'/systems/'+systemInfo.id+'/edit'} className={'btn btn-light btn-sm float-end'} ><i className={'fa fa-pencil-alt'} /> Редактирай </Link>
                                : null}
                            </div>
                            <div className={'col-lg-3 col-sm-12'} >

                                {userContext.permissions.systems_delete ?
                                    <SystemDeleteModal system={systemInfo} />
                                :
                                null}
                            </div>
                        </div>

                    </div>


                    {systemInfo.online ?


                        <div>

                            <div className={'col-lg-12'}>
                                <h5><i className={'fa fa-database'} /> ДАННИ</h5>
                            </div>
                            <div className={'col-lg-12'}>
                                <hr/>
                                <span style={{border:'1px solid lightgray',height:'50px',marginLeft:'2px',marginRight: '2px'}} />
                                <button className={'m-1 '+  (section === 'front_page' ? 'btn btn-sm btn-primary':'btn btn-sm btn-light')}  onClick={() => {setSection('front_page') } } type={'button'}> <i className={'fa fa-info-circle'} /> Информационно табло</button>
                                <span style={{border:'1px solid lightgray',height:'50px',marginLeft:'2px',marginRight: '2px'}} />
                                <button className={'m-1 '+ (section === 'settings' ? 'btn btn-sm btn-primary':'btn btn-sm btn-light')} onClick={() => {setSection('settings') } } type={'button'}> <i className={'fa fa-cog'} /> Настройки</button>
                                <span style={{border:'1px solid lightgray',height:'50px',marginLeft:'2px',marginRight: '2px'}} />
                                <button className={'m-1 '+ (section === 'invoices' ? 'btn btn-sm btn-primary':'btn btn-sm btn-light')} onClick={() => {setSection('invoices') } } type={'button'}> <i className={'fa fa-file-invoice'} /> Финансови документи</button>
                                <span style={{border:'1px solid lightgray',height:'50px',marginLeft:'2px',marginRight: '2px'}} />
                                <button className={'m-1 '+  (section === 'users' ? 'btn btn-sm btn-primary':'btn btn-sm btn-light')}  onClick={() => {setSection('users') } } type={'button'}> <i className={'fa fa-users'} /> Потребители</button>
                                <span style={{border:'1px solid lightgray',height:'50px',marginLeft:'2px',marginRight: '2px'}} />
                                <button className={'m-1 '+  (section === 'clients' ? 'btn btn-sm btn-primary':'btn btn-sm btn-light')}  onClick={() => {setSection('clients') } } type={'button'}> <i className={'fa fa-address-card'} /> Клиенти</button>
                                <span style={{border:'1px solid lightgray',height:'50px',marginLeft:'2px',marginRight: '2px'}} />
                                <button className={'m-1 '+  (section === 'accounting_items' ? 'btn btn-sm btn-primary':'btn btn-sm btn-light')}  onClick={() => {setSection('accounting_items') } } type={'button'}> <i className={'fa fa-list'} /> Номенклатури</button>

                            </div>


                            {section === 'front_page' ?
                                <div className='col-lg-12 mt-4'>

                                    {userContext.permissions.systems_front ?
                                        <FrontPage system={systemInfo} />
                                        :<AccessDenied />}
                                </div>
                                :null
                            }

                            {section === 'settings' ?
                                <div className='col-lg-12 mt-4'>
                                    {userContext.permissions.systems_settings ?
                                        <SettingsList system={systemInfo} />
                                        :<AccessDenied />}

                                </div>
                                :null
                            }

                            {section === 'invoices' ?
                                <div className='col-lg-12 mt-4'>
                                    {userContext.permissions.systems_invoices ?
                                        <InvoicesList system={systemInfo} />
                                        :<AccessDenied />}

                                </div>
                                :null
                            }

                            {section === 'users' ?
                                <div className='col-lg-12 mt-4'>

                                    {userContext.permissions.systems_users ?
                                        <UsersList system={systemInfo} />
                                        :<AccessDenied />}

                                </div>
                                :null}
                            {section === 'clients' ?
                                <div className='col-lg-12 mt-4'>


                                    {userContext.permissions.systems_clients ?
                                        <ClientsList system={systemInfo} />
                                        :<AccessDenied />}

                                </div>
                                :null}
                            {section === 'accounting_items' ?
                                <div className='col-lg-12 mt-4'>

                                    {userContext.permissions.systems_invoice_items ?
                                        <ItemsList system={systemInfo} />
                                        :<AccessDenied />}


                                </div>
                                :null}
                        </div>

                        : null
                    }



                </div>


                <ToastContainer />

            </div>
        </div>

            :
            <div className='alert alert-danger mt-2'>Error: system not found.</div>

    );
}

export default SystemPage;
