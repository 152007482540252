import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Loading from "../../layout/Loading";

function UsersList(props){

    const [users,setUsers] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const levelRef = useRef();

    function reloadUsers(){
        var params = {
            level: levelRef.current.value
        }
        axios.get(props.system.url+'/api/users',{params: params})
            .then((response)=>{
                setUsers(response.data);

            }).catch((error)=>{
            alert('ERROR WHILE GETTING USERS!');
        });
    }




    useEffect(()=>{

        reloadUsers();
        setIsLoading( prevState => true);
        setTimeout( () => {
            setIsLoading( prevState => false);
        }, 1000);
    },[props.system]);


    if(isLoading){
        return <Loading height='200px' size='25px' />
    }

    return (
      <div>
          <div className={'row'}>
              <div className={'col-lg-8 col-sm-12'}><h5><i className={'fa fa-users'} /> Потребители</h5></div>
              <div className={'col-lg-4 col-sm-12 text-end'}>
                  <div className="input-group mb-3">
                      <span className="input-group-text" id="basic-addon1"><i className={'fa fa-search'} /> </span>
                      <select className={'form-control'} onChange={( )=>{reloadUsers(); }} ref={levelRef}>
                          <option value='0'>Всички нива</option>
                          <option value='1'>Оператор</option>
                          <option value='2'>Акаунт мениджър</option>
                          <option value='3'>Клиент</option>
                      </select>
                  </div>
              </div>
          </div>
          <p>Резултати <span className={'badge bg-dark text-light'}>{users.length}</span></p>
         <table className='table table-sm'>
             <thead>
                <tr>
                    <th>#</th>
                    <th>Имена</th>
                    <th>Ниво</th>
                    <th>Email</th>
                    <th>Последен вход</th>
                    <th>Статус</th>
                </tr>
             </thead>
             <tbody>
             {users.map((user) =>
                 <tr key={user.id}>
                     <td style={{opacity:0.3}}>{user.id}</td>
                     <td><b>{user.name}</b></td>
                     <td>
                         {user.level.name}
                         {user.client !== null ? <span><br/><span className={'badge bg-light text-primary'}>{user.client.name}</span></span> : null}
                     </td>
                     <td>{user.email}</td>
                     <td>
                         {user.last_login_at ?? <span className={'badge bg-light text-warning'}><i className={'fa fa-times'}/> няма информация</span>}
                     </td>
                     <td>{user.deactivated ? <span className={'badge bg-light text-danger'}>деактивиран</span>:<span className={'badge bg-light text-success'}>активен</span>}</td>
                 </tr>
             )}
             </tbody>
         </table>
      </div>
    );

}

export default UsersList;
