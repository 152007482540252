import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import UserContext from "../../context/user-context";
import {Link} from "react-router-dom";
import SystemCard from "./SystemCard";
import Loading from "../../layout/Loading";
import AccessDenied from "../../layout/AccessDenied";
import { ToastContainer, toast } from 'react-toastify';

function SystemsPage(){
    const userContext =  useContext(UserContext);
    const searchRef = useRef();
    const [systems,setSystems] = useState([]);
    const [currentSystems,setCurrentSystems] = useState([]);
    const [caddyHosts,setCaddyHosts] = useState([]);
    const [filteredCaddyHosts,setFilteredCaddyHosts] = useState([]);


    const filterCaddyHostsRef = useRef();


    function reloadSystems(){
        var params = {q: searchRef.current.value};
        axios.get(userContext.getBackendUrl()+'/systems',{params: params})
        .then((response)=>{
            setSystems(response.data);
        })
        .catch((error)=>{
            toast.error('Грешка при намиране на системите', { position: toast.POSITION.BOTTOM_RIGHT  });

        });
    }

    function reloadCurrentSystems(){
        axios.get(userContext.getBackendUrl()+'/systems/current')
            .then((response)=>{
                setCurrentSystems(response.data);
                console.log(response.data);
            })
            .catch((error)=>{
                toast.error('Грешка при намиране на активни контейнери', { position: toast.POSITION.BOTTOM_RIGHT  });

            });
    }


    function reloadCaddyHosts(){

        axios.get(userContext.getBackendUrl()+'/caddy/hosts')
            .then((response)=>{
                setCaddyHosts(response.data);
                setFilteredCaddyHosts(response.data);


            })
            .catch((error)=>{
                toast.error('Грешка при намиране на caddy hosts', { position: toast.POSITION.BOTTOM_RIGHT  });
            });


    }


    function filterCaddyHosts(){
        setFilteredCaddyHosts(
            caddyHosts.filter((a)=>{
                if(a.domain.includes(filterCaddyHostsRef.current.value) || a.ip.includes(filterCaddyHostsRef.current.value)){
                    return a;
                }
            }));
    }


    useEffect(()=>{

        if(userContext.permissions.systems_view){

            reloadSystems();
            reloadCurrentSystems();
            reloadCaddyHosts();
        }
    },[])


    if(userContext.permissions.systems_view === false){
        return (
            <AccessDenied />
        )
    }

    return (

        <div>
            <div className={'card'}>
                <div className={'card-header'}>
                    WTBills added systems
                    {userContext.permissions.systems_create ?
                        <Link to={'/systems/create'} className={'btn btn-success float-end'}><i className={'fa fa-plus'} /> Нова система</Link>
                        :null}

                </div>
                <div className={'card-body'}>
                    <div className={'row'}>
                        <div className={'col-lg-3 col-sm-12'}>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><i className={'fa fa-search'} /> </span>
                                <input type={'text'} placeholder={'Търсете...'} onChange={()=>{ reloadSystems(); }} className={'form-control'} ref={searchRef}  />
                            </div>
                        </div>
                        <div className={'col-lg-2 col-sm-12 pt-2'}>
                            <span className={'badge bg-light text-dark'} style={{fontSize:'14px'}}>{systems.length} резултата</span>
                        </div>

                    </div>

                    <table className={'table table-striped'}>
                        <thead>
                        <tr>
                            <th>Име</th>
                            <th>Адрес</th>
                            <th>Порт</th>
                            <th>Описание</th>
                            <th>Docker Info</th>
                            {userContext.permissions.systems_edit ?
                                <th />
                            :null}
                        </tr>
                        </thead>
                        <tbody>
                        {systems.map((system)=>{
                            return <tr key={system.id}>
                                <td><b>{system.name}</b><br/><span style={{fontSize:'12px',opacity:0.5,color:'orangered'}}>{system.name_original}</span></td>
                                <td>{system.url}</td>
                                <td>{system.port}</td>
                                <td><div dangerouslySetInnerHTML={{__html: system.description}} /></td>
                                <td>
                                    {currentSystems.length ?

                                    <div >
                                        {
                                            currentSystems.filter((v) => { if(v.name === system.name_original) return v;  }).length ?
                                                <span>
                                                <b>{currentSystems.filter((v) => { if(v.name === system.name_original) return v;  })[0].name}</b> <br/>
                                                    {currentSystems.filter((v) => { if(v.name === system.name_original) return v;  })[0].address} <br/>
                                                    {currentSystems.filter((v) => { if(v.name === system.name_original) return v;  })[0].start_at},  {currentSystems.filter((v) => { if(v.name === system.name_original) return v;  })[0].start_ago} <br/>
                                                    {currentSystems.filter((v) => { if(v.name === system.name_original) return v;  })[0].port}
                                            </span>
                                                :
                                                <span className={'badge bg-danger text-white'}><i className={'fa fa-times'} /> няма намерена информация</span>

                                        }
                                    </div>
                                        :
                                        <Loading />
                                    }




                                </td>

                                {userContext.permissions.systems_edit ?

                                    <td>

                                        <Link to={'/systems/'+system.id+'/edit'} className={'btn btn-primary btn-sm float-end'}><i className={'fa fa-pencil'} /> Редактирай</Link>
                                    </td>
                                :null}
                            </tr>
                        })}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-lg-6 col-sm-12 mt-2'}>
                    <div className={'card mt-2'}>
                        <div className={'card-header'}>
                            Active containers @ 172.22.22.100
                            <span className={'badge bg-primary text-white ms-1'}>{currentSystems.length}</span>
                        </div>
                        <div className={'card-body'}>

                            {currentSystems.length ?
                                <table className={'table table-striped'} style={{fontSize:'14px'}}>
                                    <thead>
                                    <tr>
                                        <th>Name / Domain</th>
                                        <th>Up time</th>
                                        <th>Port</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentSystems.map((system)=>{
                                        return <tr key={system.id}>
                                            <td><b>{system.name}</b><br/><span style={{fontSize:'12px',opacity:0.5,color:'orangered'}}>{system.address}</span></td>
                                            <td>{system.start_at}<br/> <span style={{fontSize:'12px', opacity:0.5}}>{system.start_ago}</span></td>
                                            <td>{system.port}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>

                                :

                                <Loading />
                            }

                        </div>
                    </div>
                </div>
                <div className={'col-lg-6 col-sm-12 mt-2'}>

                    <div className={'card mt-2'}>
                        <div className={'card-header'}>
                            Caddy Hosts
                            <span className={'badge bg-primary text-white ms-1'}>{filteredCaddyHosts.length}</span>
                        </div>
                        <div className={'card-body'}>

                            {caddyHosts.length ?
                                <table className={'table table-striped'} style={{fontSize:'14px'}}>
                                    <thead>
                                    <tr>
                                        <th colSpan={3}>

                                            <div className="input-group mb-3">
                                                <span className="input-group-text" id="basic-addon1"><i className={'fa fa-search'} /> </span>
                                                <input className={'form-control'} onChange={filterCaddyHosts} ref={filterCaddyHostsRef} type={'text'} placeholder={'Търси...'} />
                                            </div>


                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Domain</th>
                                        <th>Host</th>
                                        <th>Port</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filteredCaddyHosts.map((host)=>{

                                            return <tr key={host.domain}>
                                                <td><b>{host.domain}</b></td>
                                                <td>{host.ip}</td>
                                                <td>{host.port}</td>
                                            </tr>



                                    })}
                                    </tbody>
                                </table>

                                :

                                <Loading />
                            }

                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />

        </div>

    );
}


export default SystemsPage;
