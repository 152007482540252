import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import ReactQuill from "react-quill";
import {useContext, useRef, useState} from "react";
import { useNavigate } from 'react-router-dom';
import UserContext from "../../context/user-context";

function SystemAdd(){

    let navigate = useNavigate();
    const userContext =  useContext(UserContext);
    const [description, setDescription] = useState('');

    const [isLoading,setIsLoading] = useState(false);
    const nameRef = useRef();
    const urlRef = useRef();

    function saveSystem(){



        setIsLoading(true);


        if(nameRef.current.value.length  && urlRef.current.value.length){

            var payload = {
                name: nameRef.current.value,
                url: "https://"+urlRef.current.value+".wtbills.eu",
                description: description,
            }

            axios.post(userContext.getBackendUrl()+'/systems/store',payload)
                .then((response)=>{
                    if(response.data.status === 'success'){
                        toast.success('Системата беше добавена', { position: toast.POSITION.BOTTOM_RIGHT  });
                        userContext.reloadSystems();

                        setIsLoading(false);
                        setTimeout(()=>{
                            navigate(-1);
                        },1000)

                    }else{
                        setIsLoading(false);

                        toast.error(response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                    }
                }).catch((err)=>{
                setIsLoading(false);

                toast.error('Грешка при запис', { position: toast.POSITION.BOTTOM_RIGHT  });

            });

        }else{
            setIsLoading(false);
            toast.error('Моля, попълнете Име и URL адрес на системата.', { position: toast.POSITION.BOTTOM_RIGHT  });
        }




    }


    return (
        <div className={'card'}>
            <div className={'card-header'}>
                <i className={'fa fa-plus'}/> Добавяне на нова система
            </div>
            <div className={'card-body'}>

                {userContext.permissions.systems_create ?

                    <div>
                        <p className={'mt-2 mb-1'}>Име:</p>
                        <input type={'text'} className={'form-control'} ref={nameRef} />
                        <p className={'mt-2 mb-1'}>URL:</p>
                        <div className={'row'}>
                            <div className={'col-lg-3 col-sm-12 mt-2'}>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" style={{marginTop:'8px'}}>https://</span>
                                    <input type={'url'} placeholder={'ex: alpha'} className={'form-control mt-2'} ref={urlRef} />
                                    <span className="input-group-text"  style={{marginTop:'8px'}}>.wtbills.eu</span>
                                </div>
                            </div>
                        </div>


                        <p className={'mt-2 mb-1'}>Описание:</p>
                        <ReactQuill style={{marginTop:'10px'}} theme="snow" value={description} onChange={setDescription} />
                        <br/>


                        {isLoading ?
                            <button type={'button'} disabled className={'btn btn-success float-end mt-2'} ><i className={'fa fa-sync fa-spin'} /> Моля, изчакайте....</button>
                            :
                            <button type={'button'} className={'btn btn-success float-end mt-2'} onClick={()=>{ saveSystem() }}><i className={'fa fa-save'} /> Запис</button>
                        }
                        <ToastContainer />
                    </div>

                :
                    <div className={'alert alert-warning'}><i className={'fa fa-exclamation-triangle'} /> Грешка: нямате права за достъп до тази функционалност. Моля, свържете се с администратор.</div>
                }


            </div>
        </div>
    );

}
export default SystemAdd;
