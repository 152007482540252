import {Link, useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useRef, useState} from "react";
import UserContext from "../../context/user-context";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import UserDeleteModal from "./UserDeleteModal";


function UserEdit(props){

    const params = useParams();
    let navigate = useNavigate();
    const userContext =  useContext(UserContext);

    const [user, setUser] = useState({});
    const [permissions, setPermissions] = useState([]);



    const nameRef = useRef();
    const emailRef = useRef();



    function reloadUser(){

        axios.get(userContext.getBackendUrl()+'/users/'+params.userId+'/show')
            .then((response)=>{
                setUser(response.data);
                nameRef.current.value = response.data.name;
                emailRef.current.value = response.data.email;
            }).catch((err)=>{
            toast.error('Грешка при зареждане на потребителя', { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    function loadPermissions(){
        axios.get(userContext.getBackendUrl()+'/permissions')
            .then((response)=>{
                setPermissions(response.data);
            }).catch((err)=>{
            toast.error('Грешка при зареждане на налични права', { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }



    function updateUser(){


        var data = {
            name: nameRef.current.value,
            email: emailRef.current.value
        }


        axios.post(userContext.getBackendUrl()+'/users/'+user.id+'/update',data)
            .then((response)=>{
                if(response.data.status === 'success'){
                    toast.success(response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                    reloadUser();
                }else{
                    toast.error(response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                }
            }).catch((err)=>{

            toast.error('Грешка при обновяване на информацията', { position: toast.POSITION.BOTTOM_RIGHT  });

        });

    }


    function updatePermission(permission, access){

        var data = {permission, access}

        axios.post(userContext.getBackendUrl()+'/users/'+user.id+'/permissions/update',data)
            .then((response)=>{
                if(response.data.status === 'success'){
                    toast.success(response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                    reloadUser();
                }else{
                    toast.error(response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                }
            }).catch((err)=>{

            toast.error('Грешка при обновяване на информацията', { position: toast.POSITION.BOTTOM_RIGHT  });

        });

    }

    useEffect(()=>{

        reloadUser();
        loadPermissions();

    },[params]);




    if(user.length){
        return <div className={'card'}>
            <div className={'card-body'}>
                <div className={'alert alert-warning'}>
                    <i className={'fa fa-exclamation-triangle'} /> Грешка при зареждане на потребителя
                </div>
            </div>
        </div>
    }
    return (
        <div className={'card'}>
            <div className={'card-header'}>
                <div className={'row'}>
                    <div className={'col-lg-10 col-sm-12 mt-2'}>
                        <i className={'fa fa-pencil'} /> Редакция: <b>{user.name}</b>
                    </div>
                    <div className={'col-lg-2 col-sm-12 mt-2 text-end'}>
                        {userContext.permissions.users_delete ?
                            <div>
                                <UserDeleteModal user={user} />
                            </div>
                            : null}
                    </div>
                </div>

            </div>
            <div className={'card-body'}>

                <div className={'row'}>
                    <div className={'col-lg-3 col-sm-12 mt-2'} style={{borderRight:'2px solid black'}}>
                        <h4><i className={'fa fa-address-card'}/> Информация</h4>

                        <div className={'row'}>
                            <div className={'col-lg-12 mt-2 ps-2'}>
                                Имена <br/>
                                <input type={'name'} className={'form-control'} ref={nameRef}/>
                            </div>
                            <div className={'col-lg-12 mt-2 ps-2'}>
                                Email <br/>
                                <input type={'email'} className={'form-control'} ref={emailRef}/>

                            </div>
                            <div className={'col-lg-12 text-end mt-2 mb-2'}>

                                <button className={'btn btn-success'} onClick={updateUser}><i className={'fa fa-save'}/> Запиши</button>
                                <hr/>

                            </div>
                            <div className={'col-lg-12'}>
                                Created at {new Date(user.created_at).toLocaleDateString('bg-BG')} {new Date(user.created_at).toLocaleTimeString()}
                            </div>
                            <div className={'col-lg-12'}>
                                Updated at {new Date(user.updated_at).toLocaleDateString('bg-BG')} {new Date(user.updated_at).toLocaleTimeString()}
                            </div>
                        </div>

                    </div>
                    <div className={'col-lg-9 col-sm-12 mt-2'} >
                        <h4><i className={'fa fa-lock-open'}/> Права</h4>
                        <div className={'row'}>


                            {permissions.map((permissionCategory)=>{

                                return <div className={'col-lg-4 col-sm-12 mt-2'} key={permissionCategory.name}>
                                    <h5>{permissionCategory.name}</h5>
                                    <table className={'table table-striped table-sm'}>
                                        <tbody>
                                        {permissionCategory.permissions.map((perm)=>{


                                            return <tr key={perm.system_name} >
                                                <td style={{color: user.permissions[perm.system_name] ? "forestgreen":"darkred"  }}>
                                                    {perm.name}
                                                    { (perm.description !== null && perm.description.length) ? <span><br/>{perm.description}</span> : null}
                                                </td>
                                                <td>

                                                    <input type={'checkbox'}  onChange={(e)=>{ updatePermission(perm.system_name,(e.currentTarget.checked ? 1 : 0) ) }} checked={ user.permissions[perm.system_name] }    />

                                                </td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>

                                </div>


                            })}
                        </div>
                    </div>
                </div>

                <ToastContainer />
                <Link to={'/users'} className={'btn btn-light'}><i className={'fa fa-chevron-left'} /> Назад</Link>

            </div>


        </div>
    )

}


export default UserEdit;
