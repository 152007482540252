import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../layout/Loading";

function SettingsList(props){


    const [settings,setSettings] = useState({});
    const [isLoading,setIsLoading] = useState(false);


    function reloadSettings(){
        var params = { }
        axios.get(props.system.url+'/api/settings',{params: params})
            .then((response)=>{
                setSettings(response.data);
            }).catch((error)=>{
            alert('ERROR WHILE GETTING Settings!');
        });
    }


    useEffect(()=>{
        reloadSettings();
        setIsLoading( prevState => true);
        setTimeout( () => {
            setIsLoading( prevState => false);
        }, 1000);
    },[props.system]);


    if(isLoading){
        return <Loading height='200px' size='25px' />
    }



    return (
      <div>
          <div className={'w-100 mt-2'}>
              <div className={'row'}>
                  <div className={'col-lg-6 col-sm-12'}>
                      <table className={'table table-bordered'} cellPadding={6}>
                          <tbody>

                          <tr>

                              <td colSpan={2}>{settings.logo !== null ? <p><img src={props.system.url+'/upload/settings/'+settings.logo} alt='Logo' className={'img-fluid'} /></p> : <span className={'badge bg-light text-warning'}>няма избрано лого</span>}</td>
                          </tr>
                          <tr>
                              <td style={{verticalAlign:'top',color:'grey'}}><i className={'fa fa-address-card'}/> Име на компания</td>
                              <td><b>{settings.company_name}</b></td>
                          </tr>
                          <tr>
                              <td style={{verticalAlign:'top',color:'grey'}}><i className={'fa fa-language'} /> Език при фактуриране</td>
                              <td><b>{(()=>{
                                  switch(settings.invoice_language){
                                      case "bg": return "Български език"
                                      case "en": return "Английски език"
                                      case "multiple": return "Български и английски език"
                                  }
                              })()}</b></td>
                          </tr>
                          <tr>
                              <td style={{verticalAlign:'top',color:'grey'}}><i className={'fas fa-hand-holding-dollar'} /> Начисляване на ДДС</td>
                              <td><b>
                                  {settings.tax_exclude_reason ?  "НЕ - "+settings.tax_exclude_reason.name : "ДА"}
                              </b></td>
                          </tr>
                          <tr>
                              <td style={{verticalAlign:'top',color:'grey'}}><i className={'fa fa-database'} /> Синхронизация със счетоводен софтуер</td>
                              <td>
                                  <b>{(()=>{
                                      switch(settings.send_invoices_to_accounting_software){
                                          case 0: return <span className={'badge bg-warning text-dark'}>изключено</span>
                                          case 1: return <span className={'badge bg-success text-light'}>включено</span>
                                      }
                                  })()}</b>
                              </td>
                          </tr>
                          <tr>
                              <td style={{verticalAlign:'top',color:'grey'}}><i className={'fa fa-paper-plane'} /> Изпращане на финансови документи</td>
                              <td>
                                  <b>{(()=>{
                                      switch(settings.sending_invoices_to_client_type){
                                          case "link": return "Линк за сваляне"
                                          case "attached_file": return "Прикачен файл"
                                      }
                                  })()}</b>
                              </td>
                          </tr>
                          <tr>
                              <td style={{verticalAlign:'top',color:'grey'}}><i className={'fa fa-copy'} /> Копие при изпращане на финансов документ</td>
                              <td>
                                  <b>{settings.sending_invoices_copy_to_mail ?? <span className={'badge bg-light text-dark'}>не се изпраща копие</span>}</b>
                              </td>
                          </tr>
                          <tr>
                              <td style={{verticalAlign:'top',color:'grey'}}><i className={'fa fa-envelope'} /> Subject при изпращане на финансов докуемнт</td>
                              <td>
                                  <b>{settings.sending_invoices_subject ?? <span className={'badge bg-light text-dark'}>няма въведено</span>}</b>
                              </td>
                          </tr>
                          <tr>
                              <td style={{verticalAlign:'top',color:'grey'}}><i className={'fa fa-signature'} /> Подпис при изпращане на финансов документ</td>
                              <td>
                                  <div dangerouslySetInnerHTML={{__html: settings.sending_invoices_signature}}>

                                  </div>
                              </td>
                          </tr>
                          </tbody>

                      </table>
                  </div>
              </div>

          </div>


      </div>
    );

}

export default SettingsList;
