import Modal from "react-modal";
import {useContext, useState} from "react";
import { useNavigate } from 'react-router-dom';
import {toast, ToastContainer} from "react-toastify";
import UserContext from "../../context/user-context";
import axios from "axios";

function SystemDeleteModal(props){


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let navigate = useNavigate();
    const userContext =  useContext(UserContext);



    function deleteSystem(){

        setIsLoading(true);

        axios.delete(userContext.getBackendUrl()+'/systems/'+props.system.id+'/delete')
            .then((response)=>{

                if(response.data.status === 'success'){
                    setIsLoading(false);
                    setIsModalOpen(false);
                    toast.success('Системата беше премахната', { position: toast.POSITION.BOTTOM_RIGHT  });
                    userContext.reloadSystems();
                    setTimeout(()=>{
                        navigate(-1);
                    },1000)
                }else{
                    setIsLoading(false);
                    toast.error(response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                }

            }).catch((error)=>{
            setIsLoading(false);
            toast.error('Грешка при премахване на системата', { position: toast.POSITION.BOTTOM_RIGHT  });

        });



    }

  return (
        <div>
            <button onClick={()=>setIsModalOpen(true)} className={'btn btn-danger btn-sm'}><i className={'fa fa-trash-alt'} /> Премахване</button>
            <Modal isOpen={isModalOpen}
                   animationDuration={10000}
                   ariaHideApp={false}
                   style={{
                       overlay:
                           {
                               backgroundColor: 'rgb(0,0,0,0.7)',
                           },
                       content: {
                           minWidth:'60vw',
                           top: '40%',
                           boxShadow: '1px 1px 15px black',
                           borderRadius:'10px',
                           backgroundColor: 'white',
                           left: '50%',
                           right: 'auto',
                           bottom: 'auto',
                           marginRight: '-50%',
                           transform: 'translate(-50%, -50%)',
                       }

                   }}
            >

                <div className={'alert alert-warning'}>
                    Потвърдете премахването на тази системата: <b>{props.system.name}</b> <br/>
                    Адрес: <b>{props.system.url}</b>

                    <div className={'w-100 mt-2 text-end'}>

                        {isLoading ?
                            <button className={'btn btn-danger'} disabled><i className={'fa fa-sync fa-spin'} /> Премахване....</button>
                        :
                            <button className={'btn btn-danger'} onClick={deleteSystem}><i className={'fa fa-trash'} /> ПРЕМАХВАНЕ</button>
                        }
                    </div>
                </div>


                <button className={'btn btn-outline-dark float-start'} type='button' onClick={()=>{ setIsModalOpen(false); }}><i className={'fa fa-times'} /> Затвори</button>

            </Modal>
        </div>

);

}

export default SystemDeleteModal
