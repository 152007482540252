import axios from "axios";
import {useEffect, useRef, useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loading from "../../layout/Loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FrontPage(props){


    const [frontPage,setFrontPage] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [value, setValue] = useState('');

    function reloadFrontPage(){

        var params = {  }
        axios.get(props.system.url+'/api/front_page',{params: params})
            .then((response)=>{
                setFrontPage(response.data);
                setValue(response.data);

            }).catch((error)=>{
            alert('ERROR WHILE GETTING Front Page!');
        });
    }


    function updateText(){


        axios.post(props.system.url+"/api/front_page",{text:value})
            .then((response)=>{
                reloadFrontPage();
                toast.success('Информацията беше обновена', { position: toast.POSITION.BOTTOM_RIGHT  });
            }).catch((error)=>{
            toast.error('Грешка при обновяване', { position: toast.POSITION.BOTTOM_RIGHT  });
            });
    }


    useEffect(()=>{

        reloadFrontPage();

        setIsLoading( prevState => true);
        setTimeout( () => {
            setIsLoading( prevState => false);
        }, 1000);
    },[props.system]);


    if(isLoading){
        return <Loading height='200px' size='25px' />

    }

    return(


        <div style={{width:'100%'}}>
            <ReactQuill theme="snow" value={value}  onChange={setValue}/>

            <br/>

            {isLoading ?
                <button type={'button'} className={'btn btn-success float-end'} disabled={true}><i className={'fa fa-save'} /> Обновяване.....</button>
                :
                <button type={'button'} onClick={()=>updateText()}  className={'btn btn-success float-end'}><i className={'fa fa-save'} /> Обнови съдържанието</button>
            }

            <ToastContainer />
        </div>

    )
}

export default FrontPage;
