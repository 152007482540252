
import UserContext from "../../context/user-context";
import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import AccessDenied from "../../layout/AccessDenied";
import Loading from "../../layout/Loading";

function UsersPage(){



    const userContext =  useContext(UserContext);
    let navigate = useNavigate();
    const [users,setUsers] = useState([]);
    const searchRef = useRef();




    function loadUsers(){
        var params = {
            q: searchRef.current.value,
        }
        axios.get(userContext.getBackendUrl()+'/users',{params}).then((response)=>{
            setUsers(response.data);
        }).catch((error)=>{
            alert('Error loading users');
        });


    }


    useEffect(()=>{

        if(userContext.permissions.users_view){
            loadUsers();
        }


    },[]);

    return (
        <div className={'card'}>
            <div className={'card-header'}>
                <i className={'fa fa-users'} /> Списък потребители

                {userContext.permissions.users_create ?
                    <Link to={'/users/create'} className={'btn btn-success float-end btn-sm'}><i className={'fa fa-plus'} /> Нов оператор</Link>
                :
                    null
                }
            </div>

            {userContext.permissions.users_view ?

                <div className={'card-body'}>
                    <div className={'row'}>
                        <div className={'col-lg-3'}>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><i className={'fa fa-search'} /> </span>
                                <input type={'text'} className={'form-control'} ref={searchRef} placeholder={'Търси в списък с потребители...'}  onChange={()=>{  loadUsers();}} />
                            </div>
                        </div>
                        <div className={'col-lg-2 mb-2 ps-3 pt-2'}>
                            <span className={'badge bg-light text-dark'} style={{fontSize:'14px'}}>{users.length}  резултата</span>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-lg-12'}>

                            {users.length ?
                                <table className={'table table-striped table-hover'}>
                                    <thead>
                                    <tr>
                                        <th>Имена</th>
                                        <th>E-Mail</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map((user)=>{
                                        return (
                                            <tr key={user.id}>
                                                <td style={{cursor:'pointer'}} onClick={()=>{ navigate('/users/'+user.id+'/show')}}><b>{user.name}</b> {user.id === userContext.user.id ? <span className={'badge bg-light text-primary'}>it's you</span>:""}</td>
                                                <td  style={{cursor:'pointer'}} onClick={()=>{ navigate('/users/'+user.id+'/show')}}>{user.email}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            :

                            <Loading />
                            }

                        </div>
                    </div>
                </div>

                :

                <AccessDenied />
            }


        </div>
    )

}

export default UsersPage;
