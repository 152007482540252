import {useContext, useEffect, useRef, useState} from "react";
import SystemCard from "./system/SystemCard";
import {Link} from "react-router-dom";
import axios from "axios";
import UserContext from "../context/user-context";

function Dashboard(){


    const searchRef = useRef();
    const [systems,setSystems] = useState([]);
    const userContext =  useContext(UserContext);

    function reloadSystems(){
        var params = {q: searchRef.current.value}
        axios.get(userContext.getBackendUrl()+'/systems',{params})
            .then((response)=>{
                setSystems(response.data);
            }).catch((error)=>{
            alert('Error while getting systems');
        });
    }


    useEffect(()=>{

        reloadSystems();


    },[]);

    return (
        <div>
            <h3>Welcome!</h3>
            <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1"><i className={'fa fa-search'} /> </span>
                <input type={'text'} placeholder={'Търсете...'} onChange={()=>{ reloadSystems(); }} className={'form-control'} ref={searchRef}  />
            </div>
            <div className='row pe-4'>
                {systems.map((system)=>{
                    return <div key={system.name} className='col-lg-3 col-sm-12 mt-2'><Link to={'/system/'+system.id+'/preview'} style={{textDecoration:"none"}}><SystemCard system={system} /></Link></div>
                })}
            </div>
        </div>
    )

}

export default Dashboard;
