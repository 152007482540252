import {useEffect, useRef, useState} from "react";
import axios from "axios";
import Loading from "../../layout/Loading";


function ItemsList(props){

    const [accountingItems,setAccountingItems] = useState([]);
    const [accountingCategories,setAccountingCategories] = useState([]);
    const categoryRef = useRef();
    const [isLoading,setIsLoading] = useState(false);


    function reloadItems(){
        var params = {
            category: categoryRef.current.value
        }
        axios.get(props.system.url+'/api/accounting/items',{params: params})
            .then((response)=>{
                setAccountingItems(response.data);
            }).catch((error)=>{
            alert('ERROR WHILE GETTING Accounting Items!');
        });

    }

    function reloadCategories(){
        var params = {  }
        axios.get(props.system.url+'/api/accounting/categories',{params: params})
            .then((response)=>{
                setAccountingCategories(response.data);
            }).catch((error)=>{
            alert('ERROR WHILE GETTING Accounting Categories!');
        });
    }

    useEffect(()=>{
        reloadItems();
        reloadCategories();

        setIsLoading( prevState => true);
        setTimeout( () => {
            setIsLoading( prevState => false);
        }, 1000);
    },[]);


    if(isLoading){
        return <Loading height='200px' size='25px' />
    }

    if(typeof accountingItems !== undefined){

        return (
            <div>
                <div className={'row'}>
                    <div className={'col-lg-8 col-sm-12'}><h5><i className={'fa fa-list'} /> Номенклатури</h5></div>
                    <div className={'col-lg-4 col-sm-12 text-end'}>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><i className={'fa fa-search'} /> </span>
                            <select className={'form-control'} onChange={( )=>{reloadItems(); }} ref={categoryRef}>
                                <option value='0'>Всички категории</option>
                                {accountingCategories.map((category)=>{
                                    return (<option key={category.id} value={category.id}>{category.name}</option>)
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <p>Резултати <span className={'badge bg-dark text-light'}>{accountingItems.length}</span></p>
                <table className='table table-sm'>
                    <thead>
                    <tr>
                        <th style={{color:'grey'}}>Номенклатура</th>
                        <th style={{color:'grey'}}>Категория</th>
                        <th style={{color:'grey'}}>Добавен на</th>
                        <th style={{color:'grey'}}>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    {accountingItems.map((item) =>
                        <tr key={item.id}>
                            <td><b>{item.name}</b><br/><i style={{opacity:0.5}}>{item.name_en}</i></td>
                            <td>{item.category.name}<br/><i style={{opacity:0.5}}>{item.category.name_en}</i></td>
                            <td>{new Date(item.created_at).toDateString()} {new Date(item.created_at).toLocaleTimeString()}</td>
                            <td>{item.deactivated ? <span className={'badge bg-light text-danger'}>деактивиран</span>:<span className={'badge bg-light text-success'}>активен</span>}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div> );
    }else{
        return <div>NO results</div>
    }


}

export default ItemsList;
