import {useEffect, useState} from "react";
import axios from "axios";


function Limits(props){



    const availableLimits = [
        {system_name:'max_operators',name: 'Брой оператори',description: 'Позволен максимален брой активни оператори на системата'},
        {system_name:'max_clients',name: 'Брой клиенти',description: 'Позволен максимален брой активни клиенти в системата'},
    ]


    const [currentLimits, setCurrentLimits] = useState([]);


    function reloadCurrentLimits(){
        var params = {  }
        axios.get(props.system.url+'/api/limits',{params: params})
            .then((response)=>{
                setCurrentLimits(response.data);

            }).catch((error)=>{
            alert('ERROR WHILE GETTING Limits!');
        });
    }

    useEffect(()=>{
        reloadCurrentLimits();


    },[props.system]);


    function updateLimit(name){


        var data = {
            name: name,
            count: document.getElementById('limit_'+name).value
        }

        axios.post(props.system.url+'/api/limits/store',data)
            .then((response)=>{
                if(response.data.status === 'success'){
                    reloadCurrentLimits();
                }else{
                    alert(response.data.message);
                }

            }).catch((error)=>{
            alert('ERROR WHILE Setting Limits!');
        });


    }


    function percentLabel(type){



        let l = currentLimits.filter((el) => { return el.name === type}).length ? currentLimits.filter((el) => { return el.name === type})[0].count : 0;
        let c = 0;

        if(type === 'max_clients'){
            c = parseInt(props.system.info.clients);
        }else if(type === 'max_operators'){
            c = parseInt(props.system.info.operators);
        }

        var percent = 0;

        if(c > 0){
            percent = parseFloat(c/l * 100).toFixed(2);
        }



        var classname = 'bg-success text-white';
        if(parseFloat(percent) > 70) classname = 'bg-warning text-dark';
        if(parseFloat(percent) > 90) classname = 'bg-danger text-light';

        return (
            <span className={'badge w-100 '+classname}><span style={{fontSize:'14px'}}>{percent}%</span>   <span style={{fontSize:'90%'}} className={'badge bg-light text-dark ms-2 float-end'}>{c} / {l}</span></span>
        )

    }

    return (
        <div className={'w-100'}>
            <table className={'table table-striped table-bordered table-sm'} style={{fontSize:'12px'}}>

                <thead>
                <tr style={{fontSize:'12px'}}>
                    <th>Ограничение</th>
                    <th>Текуща стойност</th>
                    <th>Нова стойност</th>
                    <th />
                </tr>
                </thead>
                <tbody>
                {availableLimits.map((limit)=>{

                    return (<tr key={limit.system_name}>
                        <td><b>{limit.name}</b><br /><i style={{fontSize:'12px'}}>{limit.description}</i></td>
                        <td style={{verticalAlign:'middle',textAlign:'center',fontSize:'14px',fontWeight:'bold'}}>
                            {currentLimits.filter((el) => { return el.name === limit.system_name}).length ? currentLimits.filter((el) => { return el.name === limit.system_name})[0].count : <span className={'badge bg-light text-success'}>няма ограниение</span>}

                            {currentLimits.filter((el) => { return el.name === limit.system_name}).length ? <span><br/>{percentLabel(limit.system_name)}</span>:null}
                        </td>
                        <td  style={{verticalAlign:'middle',textAlign:'center'}}>
                            <input style={{maxWidth:'120px'}} className={'form-control text-end pe-3'} type='number' step='1' onChange={()=>{   }} id={'limit_'+limit.system_name} />
                        </td>
                        <td style={{verticalAlign:'middle',textAlign:'center'}}>
                            <button type={'button'} className={'btn btn-success float-end btn-sm'} onClick={()=>{ updateLimit(limit.system_name) }} ><i className={'fa fa-save'} /> Обнови</button>
                        </td>
                    </tr>);

                })}
                </tbody>

            </table>
        </div>
    )
}

export default Limits;
