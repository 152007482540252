import {useContext, useRef, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import UserContext from "../../context/user-context";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import AccessDenied from "../../layout/AccessDenied";


function UserAdd(){

    const userContext =  useContext(UserContext);
    let navigate = useNavigate();

    const nameRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const emailRef = useRef();

    const [isLoading, setIsLoading] = useState(false);


    function saveUser(){


        setIsLoading(true);

        var data = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value,
        }

        if(data.password.length === 0){
            toast.error('Не сте въвели парола за достъп', { position: toast.POSITION.BOTTOM_RIGHT  });
            setIsLoading(false);
            return false;
        }

        if(data.password.length  < 8){
            toast.error('Паролата трябва да е поне 8 символа. ', { position: toast.POSITION.BOTTOM_RIGHT  });
            setIsLoading(false);
            return false;
        }

        if(data.password !== confirmPasswordRef.current.value){
            toast.error('Паролите не съвпадат', { position: toast.POSITION.BOTTOM_RIGHT  });
            setIsLoading(false);
            return false;
        }

        if(data.email.length === 0){
            toast.error('Не сте въвели email адрес за достъп', { position: toast.POSITION.BOTTOM_RIGHT  });
            setIsLoading(false);
            return false;
        }

        if(data.name.length === 0){
            toast.error('Не сте въвели имена', { position: toast.POSITION.BOTTOM_RIGHT  });
            setIsLoading(false);
            return false;
        }


        axios.post(userContext.getBackendUrl()+'/users/store',data).then((response)=>{
            if(response.data.status === 'success'){
                toast.error(response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                setIsLoading(false);
                navigate('/users');
            }else{
                toast.error(response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                setIsLoading(false);

            }
        }).catch((err)=>{
            toast.error("Грешка при създаване на потребител", { position: toast.POSITION.BOTTOM_RIGHT  });
            setIsLoading(false);

        })



    }

    return (
        <div className={'card'}>
            <div className={'card-header'}>
                <i className={'fa fa-plus'} /> Добавяне на нов оператор
            </div>

            {userContext.permissions.users_create ?

                <div className={'card-body'}>
                    <div className={'row'}>
                        <div className={'col-lg-4 col-sm-12 mt-2'}>
                            <div className={'form-group mt-2'}>
                                <span className={'ps-1'}><i className={'fa fa-user'}/> Имена: </span> <br/>
                                <input type={'text'} className={'form-control'} ref={nameRef} />
                            </div>
                            <div className={'form-group mt-2'}>
                                <span className={'ps-1'}><i className={'fa fa-envelope'}/> Email: </span> <br/>
                                <input type={'email'} className={'form-control'} ref={emailRef} />
                            </div>
                            <div className={'form-group mt-2'}>
                                <span className={'ps-1'}><i className={'fa fa-lock'}/> Password: </span> <br/>
                                <input type={'password'} className={'form-control'} ref={passwordRef} />
                            </div>
                            <div className={'form-group mt-2'}>
                                <span className={'ps-1'}><i className={'fa fa-lock'}/> Confirm password: </span> <br/>
                                <input type={'password'} className={'form-control'} ref={confirmPasswordRef} />
                            </div>
                            <div className={'form-group mt-2'}>
                                {isLoading  ?
                                    <button type={'button'}  disabled className={'btn btn-success float-end'}><i className={'fa fa-save'} />  Моля, изчакайте...</button>
                                    :
                                    <button type={'button'} onClick={saveUser} className={'btn btn-success float-end'}><i className={'fa fa-save'} />  Създаване на потребител</button>
                                }
                            </div>
                        </div>
                    </div>

                    <ToastContainer />

                </div>

                :
                <AccessDenied />
            }

        </div>
    )


}

export default UserAdd;
