import {Link, useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import UserContext from "../../context/user-context";
import {toast, ToastContainer} from "react-toastify";
import AccessDenied from "../../layout/AccessDenied";


function UserShow(){

    const params = useParams();
    let navigate = useNavigate();
    const userContext =  useContext(UserContext);

    const [user, setUser] = useState({});
    const [permissions, setPermissions] = useState([]);


    function reloadUser(){

        axios.get(userContext.getBackendUrl()+'/users/'+params.userId+'/show')
            .then((response)=>{
                setUser(response.data);
            }).catch((err)=>{
            toast.error('Грешка при зареждане на потребителя', { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    function loadPermissions(){
        axios.get(userContext.getBackendUrl()+'/permissions')
            .then((response)=>{
                setPermissions(response.data);
            }).catch((err)=>{
            toast.error('Грешка при зареждане на налични права', { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    useEffect(()=>{


        if(userContext.permissions.users_view){

            reloadUser();
            loadPermissions();

        }
    },[params]);

    if(userContext.permissions.users_view === false){
        return <div className={'card'}>
            <div className={'card-body'}>
                <AccessDenied />
            </div>
        </div>
    }

    if(user.length){
        return <div className={'card'}>
            <div className={'card-body'}>
                <div className={'alert alert-warning'}>
                    <i className={'fa fa-exclamation-triangle'} /> Грешка при зареждане на потребителя
                </div>
            </div>
        </div>
    }
    return (
        <div className={'card'}>
            <div className={'card-header'}>
                <i className={'fa fa-user'} /> {user.name}

                {userContext.permissions.users_edit ?
                    <Link to={'/users/'+user.id+'/edit'} className={'btn btn-primary btn-sm float-end'}><i className={'fa fa-pencil'} /> Редакция</Link>
                    : null}
            </div>
            <div className={'card-body'}>


                <div className={'row'}>
                    <div className={'col-lg-3 col-sm-12 mt-2'} style={{borderRight:'2px solid black'}}>
                        <h4><i className={'fa fa-address-card'}/> Информация</h4>
                        <table>
                            <tbody>
                                <tr> <td style={{fontWeight: 'bold'}}>Имена</td> <td className={'ps-2'}>{user.name}</td></tr>
                                <tr> <td style={{fontWeight: 'bold'}}>Email</td> <td className={'ps-2'}>{user.email}</td></tr>
                                <tr> <td style={{fontWeight: 'bold'}}>Created at</td> <td className={'ps-2'}>{new Date(user.created_at).toLocaleDateString('bg-BG')} {new Date(user.created_at).toLocaleTimeString()}</td></tr>
                                <tr> <td style={{fontWeight: 'bold'}}>Updated at</td> <td className={'ps-2'}>{new Date(user.updated_at).toLocaleDateString('bg-BG')} {new Date(user.updated_at).toLocaleTimeString()}</td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={'col-lg-9 col-sm-12 mt-2'} >
                        <h4><i className={'fa fa-lock-open'}/> Права</h4>
                        <div className={'row'}>


                            {permissions.map((permissionCategory)=>{

                                return <div className={'col-lg-4 col-sm-12 mt-2'} key={permissionCategory.name}>
                                    <h5>{permissionCategory.name}</h5>
                                    <table className={'table table-striped table-sm'}>
                                        <tbody>
                                        {permissionCategory.permissions.map((perm)=>{


                                            return <tr key={perm.system_name} >
                                                <td style={{color: (user.hasOwnProperty('permissions')  && user.permissions[perm.system_name]) ? "forestgreen":"darkred"  }}>
                                                    {perm.name}
                                                    { (perm.description !== null && perm.description.length) ? <span><br/>{perm.description}</span> : null}
                                                </td>
                                                <td>

                                                    {user.hasOwnProperty('permissions') ?

                                                        <div>
                                                            {user.permissions[perm.system_name] ?
                                                                <i className={'fa fa-check'} style={{color:'forestgreen'}} />
                                                                :
                                                                <i className={'fa fa-times'} style={{color:'darkred'}} />
                                                            }
                                                        </div>

                                                    :

                                                    <span>няма информация</span>}

                                                </td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>

                                </div>


                            })}
                        </div>
                    </div>
                </div>

                <ToastContainer />
                <Link to={'/users'} className={'btn btn-light'}><i className={'fa fa-chevron-left'} /> Назад</Link>

            </div>


        </div>
    )
}

export default UserShow;
