import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import UserContext from "../../context/user-context";

function SystemStop(props){
    const userContext =  useContext(UserContext);

    const [systemStopMessage,setSystemStopMessage] = useState({message:null,date: null});
    const systemStopMessageTextarea = useRef();
    function reloadSystemStopMessage(){

        var url = props.system.url;

        axios(url+'/api/system_stop').then((response)=>{
            if(response.data.hasOwnProperty('message')){
                setSystemStopMessage({message:response.data.message,date: response.data.date_time});
            }else setSystemStopMessage({message:null,date: null});
        }).catch((error)=>{
            setSystemStopMessage({message:null,date: null});
        });
    }


    function removeStopMessageHandler(){
        var url = props.system.url;
        axios.delete(url+"/api/system_stop")
            .then((response) =>{
                reloadSystemStopMessage();
                toast.success('Системата беше пусната успешно ', { position: toast.POSITION.BOTTOM_RIGHT });
            })
            .catch(error => {
                toast.error('Възникна грешка при пускане на системата ', { position: toast.POSITION.BOTTOM_RIGHT });
            });
    }

    function stopSystemHandler(){

        var message = systemStopMessageTextarea.current.value;
        var url = props.system.url;

        if(message.length){

            axios.post(url+'/api/system_stop',{message})
                .then((response)=>{
                    reloadSystemStopMessage();
                    toast.success('Системата беше спряна успешно ', { position: toast.POSITION.BOTTOM_RIGHT });
                }).catch((error)=>{

                toast.error('Възникна грешка при спиране на системата ', { position: toast.POSITION.BOTTOM_RIGHT });

            });
        }else{
            toast.warning('Грешка: моля въведете съобщение, което ще бъде показано на страницата: '+url, { position: toast.POSITION.BOTTOM_RIGHT  });
        }


    }

    useEffect(()=>{
        if(userContext.permissions.systems_stop){
            reloadSystemStopMessage();
        }

    },[]);


    return (

        <div>
            {systemStopMessage.message != null &&  systemStopMessage.message.length ?
                <div>
                    <div className={'alert alert-warning'}>Към момента системата е спряна със съобщение: <br/><b>{systemStopMessage.message}</b><br/><span style={{fontSize:'12px'}}><i className={'fa fa-clock'} /> Спряна на: {systemStopMessage.date}</span></div>
                    <button type='button' className='btn btn-success mt-2' onClick={removeStopMessageHandler}><i className='fa-solid fa-plug-circle-check' /> ПУСКАНЕ НА СИСТЕМАТА</button></div>
                :
                <div>
                    <hr/>
                    <div className={'card'}>
                        <div className={'card-header'}>
                            <p><i className={'fa fa-comment-dots'} /> Въведете съобщение за спиране на системата:</p>
                        </div>
                        <div className={'card-body'}>
                            <textarea id='systemStopMessage' placeholder={'Въведете съобщение за спиране на системата....'} className='form-control' ref={systemStopMessageTextarea} />

                        </div>
                        <div className={'card-footer'}>
                            <button type='button' className='btn btn-warning float-end mt-2' onClick={stopSystemHandler}><i className={'fa fa-power-off'} /> Спиране на системата</button>
                        </div>
                    </div>

                </div>
            }
        </div>
    );

}


export default SystemStop;
