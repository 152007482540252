import ReactQuill from "react-quill";
import {Link} from "react-router-dom";
import {useContext, useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import UserContext from "../../context/user-context";

import { useNavigate } from 'react-router-dom';
import AccessDenied from "../../layout/AccessDenied";

function VersionCreate(){
    const userContext =  useContext(UserContext);
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    let navigate = useNavigate();


    function saveVersion(){


        if(description.length && title.length && date.length){
            axios.post(userContext.getBackendUrl() + '/versions/store',{title,description,date})
                .then((response)=>{
                    toast.success('Новата версия беше записана', { position: toast.POSITION.BOTTOM_RIGHT  });
                    setTimeout(()=>{
                        navigate('/versions');
                    },1000);
                }).catch((error)=>{
                toast.error('Грешка при запис на нова версия', { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }else{
            toast.warning('Моля, попълнете всички полета', { position: toast.POSITION.BOTTOM_RIGHT  });
        }


    }

    return (
        <div className={'card'}>

            {userContext.permissions.versions_create ?
                <div className={'card-body'}>
                    <input type={'text'} className={'form-control mb-2'} placeholder={'Въведете име на версията....'} value={title} onChange={(e) => setTitle(e.target.value)} />

                    <input type={'date'} className={'form-control mb-2'} style={{maxWidth:'200px'}} onChange={(e)=> setDate(e.target.value)} />
                    <ReactQuill theme="snow" value={description}  onChange={setDescription}/>

                    <div className={'w-100 mt-2'}>
                        <Link to={'/versions'} className={'btn btn-light float-start'}><i className={'fa fa-chevron-left'} /> Назад</Link>
                        <button onClick={saveVersion} type={'button'} className={'btn btn-success float-end'}><i className={'fa fa-save'} /> Запис</button>
                    </div>
                </div>
                :<AccessDenied />}


            <ToastContainer />
        </div>
    );
}

export default VersionCreate;
