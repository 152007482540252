import {Link, useParams} from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useContext, useEffect, useState} from "react";
import UserContext from "../../context/user-context";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VersionDeleteModal from "./VersionDeleteModal";
import AccessDenied from "../../layout/AccessDenied";

function VersionEdit(props){
    const params = useParams();
    const userContext =  useContext(UserContext);

    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [version,setVersion] = useState({});




    function loadVersion(){

        axios.get(userContext.getBackendUrl() + '/versions/'+params.versionId+'/show')
            .then((response)=>{
                setDescription(response.data.description);
                setTitle(response.data.title);
                setDate(response.data.date);
                setVersion(response.data);

            }).catch((error)=>{
            alert('Error while loading version');
        });
    }

    function updateVersion(){



        if(date.length && title.length && description.length){
            axios.post(userContext.getBackendUrl() + '/versions/'+params.versionId+'/update',{description,title,date})
                .then((response)=>{

                    if(response.data.status === 'success'){
                        loadVersion();

                        console.log("SHOW TOAST!");
                        toast.success('Информацията беше обновена', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }else{
                        toast.error('Грешка при обновяване на версията', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }
                }).catch((error)=>{
                alert('Error while updating version.');
            });
        }else{
            toast.warning('Моля, попълнете всички полета', { position: toast.POSITION.BOTTOM_RIGHT  });
        }


    }

    useEffect(()=>{

        loadVersion();
        // eslint-disable-next-line
    },[params])

    return (
        <div className={'card'}>
            <div className={'card-header'}>
                <div className={'row'}>
                    <div className={'col-lg-10 col-sm-12'}>
                        <input type={'text'} className={'form-control'} value={title} onChange={(e)=> setTitle(e.target.value)} />
                    </div>
                    <div className={'col-lg-2 col-sm-12 text-end'}>
                        {userContext.permissions.versions_delete ?
                        <VersionDeleteModal version={version} />:null}
                    </div>
                </div>

            </div>

            {userContext.permissions.versions_edit ?
                <div className={'card-body'}>
                    <input type={'date'} className={'form-control mb-2'} value={date} style={{maxWidth:'200px'}} onChange={(e)=> setDate(e.target.value)} />
                    <ReactQuill theme="snow" value={description}  onChange={setDescription}/>
                    <div className={'w-100 mt-2'}>
                        <Link to={'/versions'} className={'btn btn-light float-start'}><i className={'fa fa-chevron-left'} /> Назад</Link>
                        <button onClick={updateVersion} type={'button'} className={'btn btn-success float-end'}><i className={'fa fa-save'} /> Обнови</button>
                    </div>
                </div>

                : <AccessDenied />}



            <ToastContainer />
        </div>
    );

}

export default VersionEdit;
