import {useEffect, useState} from "react";
import axios from "axios";
import classes from '../../css/General.module.css'
import Loading from "../../layout/Loading";

function SystemCard(props){

    const [system,setSystem] = useState(props.system);
    const [isLoading, setIsLoading] = useState(true);
    const [systemStopMessage,setSystemStopMessage] = useState({message:null,date: null});

    useEffect(()=>{

        axios(system.url+'/api/info_simple').then((response)=>{

            setSystem((prev)=>({
                ...prev,
                info: response.data,
                online: true,
            }));

        }).catch((error)=>{

            setSystem((prev)=>({
                ...prev,
                info: null,
                online: false,
            }));
        });

        axios(system.url+'/api/system_stop').then((response)=>{
            if(response.data.hasOwnProperty('message')){
                setSystemStopMessage({message:response.data.message,date: response.data.date_time});
            }else setSystemStopMessage({message:null,date: null});
        }).catch((error)=>{
            setSystemStopMessage({message:null,date: null});
        });


        setTimeout(()=>{
            setIsLoading(false);
        },1500);

    },[]);



    return (
      <div className={'card shadow mt-2 '+classes.enlargeHover}>
          <div className='card-header'>
              <b className={classes.orange}>{system.name}</b>
              {systemStopMessage.message != null && systemStopMessage.message.length ? <span className={'badge bg-warning text-dark float-end ms-1 mt-1 '+classes.blink} style={{fontSize:'9px'}}>SUSPENDED</span> : null}
              {system.online ? <span className='badge bg-success text-light float-end mt-1' style={{fontSize:'9px'}}>ONLINE</span> : <span className={'badge bg-danger text-light float-end mt-1 '+classes.blink} style={{fontSize:'9px'}}>OFFLINE</span>}
          </div>
          <div className='card-body'>

              {isLoading === true ?
              <Loading height={'100px'} size={'20px'} />:
                   <div className='w-100'>
                  {system.hasOwnProperty('info') && system.info !== null  ?
                          <div className='w-100 mt-1'>
                              <span className={'badge bg-dark text-white m-1 '} title={'Брой потребители'} style={{fontSize:'13px'}}><i className={'fa fa-users'}/><span className={classes.showOnHover} style={{fontSize:'10px',margin:'2px'}}>Брой потребители</span> {system.info.users}</span>
                              <span className={'badge bg-dark text-white m-1 '} title={'Издадени финансови документи'} style={{fontSize:'13px'}}><i className={'fa fa-file-invoice'}/><span className={classes.showOnHover} style={{fontSize:'10px',margin:'2px'}}>Издадени финансови документи</span> {system.info.invoices}</span>
                              <span className={'badge bg-dark text-white m-1 '} title={'Брой клиенти'} style={{fontSize:'13px'}}><i className={'fa fa-address-card'}/><span className={classes.showOnHover} style={{fontSize:'10px',margin:'2px'}}>Брой клиенти</span> {system.info.clients}</span>
                              <span className={'badge bg-dark text-white m-1 '} title={'Брой договори'} style={{fontSize:'13px'}}><i className={'fa fa-file-signature'}/><span className={classes.showOnHover} style={{fontSize:'10px',margin:'2px'}}>Брой договори</span> {system.info.contracts}</span>
                              <span className={'badge bg-dark text-white m-1 '} title={'Брой анекси'} style={{fontSize:'13px'}}><i className={'fa fa-copy'}/><span className={classes.showOnHover} style={{fontSize:'10px',margin:'2px'}}>Брой анекси</span> {system.info.annexes}</span>
                          </div>:
                          <div className='alert alert-warning'><i className={'fa fa-exclamation-triangle'} /> Няма намерена информация</div>
                  }
                   </div>
              }


          </div>
          <div className='card-footer'>
              {system.url}
          </div>
      </div>
    );
}

export default SystemCard;
